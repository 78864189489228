import { SET_PREV_NAV_ROUTE, SET_NEXT_NAV_ROUTE } from "../types/navTypes";

const initialState = {
  prevRoute: null,
  nextRoute: null
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_PREV_NAV_ROUTE:
      return {
        ...state,
        prevRoute: action.prevRoute
      };
    case SET_NEXT_NAV_ROUTE:
      return {
        ...state,
        nextRoute: action.nextRoute
      };
    default:
      return state;
  }
};

export default reducer;
