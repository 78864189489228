export default {
  COLORS: {
    DEFAULT: "#DCDCDC",
    PRIMARY: "#9C26B0",
    LABEL: "#FE2472",
    INFO: "#00BCD4",
    ERROR: "#F44336",
    SUCCESS: "#4CAF50",
    WARNING: "#FF9800",
    MUTED: "#979797",
    INPUT: "#DCDCDC",
    ACTIVE: "#9C26B0",
    BUTTON_COLOR: "#9C26B0",
    PLACEHOLDER: "#9FA5AA",
    SWITCH_ON: "#9C26B0",
    SWITCH_OFF: "#D4D9DD",
    GRADIENT_START: "#6B24AA",
    GRADIENT_END: "#AC2688",
    PRICE_COLOR: "#EAD5FB",
    BORDER_COLOR: "#E7E7E7",
    BLOCK: "#E7E7E7",
    CAPTION: "#4A4A4A",
    GRADIENT_START_2: "#6C24AA",
    GRADIENT_END_2: "#15002B",
    WHITE: "#FFFFFF",
    IVORY: "#fffff0",
    BLACK: "#000000",
    ORANGE_DEEP: "#DD2C00",
    INDIGO_DEEP: "#304FFE",
    TEAL_DEEP: "#00BFA5",
    GREEN_DARK: "#00C853",
    LABEL_DARK: "#C40047",
    LABEL_LIGHT: "#FF67A0",
    DESCRIPTION: "#999999",
    DARK_PURPLE: "#3F51B5",
    PINK: "#D81B60",
    BLUE: "#1E88E5",
  },
  SIZES: {
    BASE: 16,
    BLOCK_SHADOW_RADIUS: 2,
  },
};
