import React, { useEffect } from "react";
import VideoPlayer from "components/Video/VideoPlayer";
// nodejs library that concatenates classes
import classNames from "classnames";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter";
import Button from "components/CustomButtons/Button.js";
// @material-ui icons
import Favorite from "@material-ui/icons/Favorite";
import ChatBubble from "@material-ui/icons/ChatBubble";
// @material-ui/core components
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { isSocialAvatarUserImage } from "../../../../store/utils/holler";

import styles from "assets/jss/material-kit-pro-react/views/ecommerceSections/latestOffersStyle.js";

import {
  categoryForId,
  DEFAULT_CATEGORY,
  DEFAULT_CATEGORY_ALT,
} from "../../../../constants/categories";
import isEmpty from "lodash.isempty";
import { profileImg } from "../../../../shared/avatar";
import { isMobileDevice } from "../../../../shared/utility";
import { typesForId } from "../../../../constants/entities";
import Badge from "../../../../components/Badge/Badge";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { getHollers } from "../../../../store/actions/holler";
import CircularProgress from "@material-ui/core/CircularProgress";
import { extractHollerShowcaseImage } from "../../../../store/utils/holler";

const useStyles = makeStyles(styles);

const formatTitle = (title) => {
  if (!title) return "";
  if (title.length > 48) {
    return title.substring(0, 48) + "...";
  }
  return title;
};

const formatDescription = (description) => {
  if (!description) return "";
  if (description.length > 70) {
    return description.substring(0, 70) + "...";
  }
  return description;
};

const formatAuthor = (displayName) => {
  if (!displayName) return "";
  if (displayName.length > 15) {
    return displayName.substring(0, 15) + "...";
  }
  return displayName;
};

const renderHollerCard = (holler, classes) => {
  const { docId, title, address, createdAt, desc, categories } = holler;
  const { videos } = holler;
  const { displayName, userImage, type } = holler;
  const { likes, commentCount } = holler;
  const category = isEmpty(categories) ? DEFAULT_CATEGORY_ALT : categories[0];
  const categoryMeta = categoryForId(category);
  const avatar = profileImg(
    {
      photoURL: userImage,
      displayName,
    },
    236
  );
  const backgroundImage = extractHollerShowcaseImage(holler);
  const previewLink = `/preview/${docId}`;
  const liveMapLink = `/map?hollerId=${docId}`;
  const target = isMobileDevice(navigator) ? "_self" : "_blank";
  const badgeMeta = typesForId(type);
  const isSocialAvatar = isSocialAvatarUserImage(userImage);

  return (
    <GridItem xs={12} sm={4} md={4} lg={4} xl={4} key={docId}>
      <Card plain blog>
        <CardHeader image plain>
          <a href={previewLink} target={target} rel="noopener noreferrer">
            {!isEmpty(videos) ? (
              <VideoPlayer
                url={videos[0].downloadURL}
                muted
                loop
                width={"100%"}
                height={235}
                config={{
                  file: {
                    attributes: {
                      style: {
                        objectFit: "cover",
                        width: "100%",
                        height: "100%",
                        borderRadius: 6,
                      },
                    },
                  },
                }}
              />
            ) : (
              <img
                src={backgroundImage}
                alt="..."
                height={235}
                style={{
                  // objectFit: isSocialAvatar ? "scale-down" : "cover",
                  objectFit: "cover",
                }}
              />
            )}
          </a>
          <div
            className={classes.coloredShadow}
            style={{
              backgroundImage: "url(" + backgroundImage + ")",
              opacity: "1",
            }}
          />
        </CardHeader>
        <CardBody plain>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <div
              style={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <Badge color={categoryMeta.colorTag}>{categoryMeta.title}</Badge>
              <Badge color={badgeMeta.colorTag}>{badgeMeta.title}</Badge>
            </div>
            <div
              className={classes.description}
              style={{
                fontSize: 12,
                color: "#3C4858",
              }}
            >
              {moment(createdAt).fromNow()}
            </div>
          </div>
          <h4
            className={classes.cardTitle}
            style={{
              textAlign: "left",
            }}
          >
            <a href={previewLink} target={target} rel="noopener noreferrer">
              {formatTitle(title)}
            </a>
          </h4>
          {desc && (
            <p className={classes.description}>{formatDescription(desc)}</p>
          )}
          <p className={classes.description}>{address}</p>
          <p className={classes.description}>
            <a href={liveMapLink} target={target} rel="noopener noreferrer">
              {" "}
              View On Live Map{" "}
            </a>
          </p>
        </CardBody>
        <CardFooter className={classes.authorFooter}>
          <div className={classes.author}>
            <img
              src={avatar}
              alt={displayName}
              className={classes.imgRaised + " " + classes.avatar}
            />
            <span>{formatAuthor(displayName)}</span>
          </div>
          <div className={classes.statsB + " " + classes.mlAuto}>
            <Favorite />
            {likes} ·
            <ChatBubble />
            {commentCount}
          </div>
        </CardFooter>
      </Card>
    </GridItem>
  );
};

export default function SectionLatestOffers() {
  const classes = useStyles();
  const theme = useTheme();
  const dispatch = useDispatch();
  const latestHollers = useSelector((state) => state.holler.latestHollers);
  const hollerIsLoading = useSelector((state) => state.ui.hollerIsLoading);
  const hollerLatestSnapshot = useSelector(
    (state) => state.holler.hollerLatestSnapshot
  );

  useEffect(() => {
    dispatch(getHollers(null, 0, DEFAULT_CATEGORY, false, null, null, 3));
  }, [dispatch]);

  const loadMoreLatest = () => {
    dispatch(
      getHollers(
        null,
        0,
        DEFAULT_CATEGORY,
        false,
        hollerLatestSnapshot,
        null,
        3
      )
    );
  };

  return isEmpty(latestHollers) ? null : (
    <div className={classes.section}>
      <div className={classes.container}>
        <h2>Latest Hollers</h2>
        <GridContainer>
          {Object.keys(latestHollers).map((hollerId) =>
            renderHollerCard(latestHollers[hollerId], classes)
          )}
        </GridContainer>
        {!isEmpty(latestHollers) && (
          <GridContainer>
            <GridItem
              md={3}
              sm={9}
              xs={9}
              className={classNames(classes.mlAuto, classes.mrAuto)}
            >
              {hollerIsLoading ? (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <CircularProgress
                    color="secondary"
                    size={30}
                    classes={{
                      root: classes.loading,
                    }}
                  />
                </div>
              ) : (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <Button round color="rose" onClick={loadMoreLatest}>
                    Load more ...
                  </Button>
                </div>
              )}
            </GridItem>
          </GridContainer>
        )}
      </div>
    </div>
  );
}
