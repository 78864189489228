import {
  SET_PLAN,
  GET_USER_TRANSACTIONS,
  RESET_TRANSACTIONS,
  TRANSACTION_LATEST_SNAPSHOT
} from "../types/planTypes";

const initialState = {
  plan: null,
  userTransactions: {},
  transactionLatestSnapshot: null
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_PLAN:
      return {
        ...state,
        plan: action.plan
      };
    case GET_USER_TRANSACTIONS:
      return {
        ...state,
        userTransactions: {
          ...state.userTransactions,
          ...action.userTransactions
        }
      };
    case RESET_TRANSACTIONS:
      return {
        ...state,
        userTransactions: action.userTransactions
      };
    case TRANSACTION_LATEST_SNAPSHOT:
      return {
        ...state,
        transactionLatestSnapshot: action.transactionLatestSnapshot
      };
    default:
      return state;
  }
};

export default reducer;
