import React, { Component } from "react";
import { Route, Switch, withRouter, Redirect } from "react-router-dom";
import { connect } from "react-redux";
import "react-image-crop/lib/ReactCrop.scss";
import { auth } from "./shared/firebase";
import { PageView, initGA, initGTM } from "./components/Tracking";
import AuthenticationModal from "./containers/Authentication/AuthenticationModal";
import FilterModal from "./containers/Holler/FilterModal";
import asyncComponent from "./hoc/asyncComponent/asyncComponent";
import AuthRoute from "./hoc/authentication/AuthRoute";
import PresentationPage from "./containers/Presentation/PresentationD/Presentation";
import ConsecutiveSnackbars from "./components/Snackbar/ConsecutiveSnackbars";
import { setSnackBarMessage } from "./store/actions/ui";
import { authenticated, setAuthUser } from "./store/actions/auth";
import HollerEditModal from "./containers/Holler/HollerEditModal";
import {
  HOME_ROUTE,
  LEARN_MORE_ROUTE,
  MAP_ROUTE,
  PROFILE_ROUTE,
  HOLLERS_ROUTE,
  MY_HOLLERS_ROUTE,
  SIGN_OUT_ROUTE,
  CHANGE_PASSWORD_ROUTE,
  CONTACT_ROUTE,
  ABOUT_ROUTE,
  PRIVACY_ROUTE,
  TERMS_ROUTE,
  RULES_ROUTE,
  SIGN_UP_ROUTE,
  PRICING_ROUTE,
  TRANSACTIONS_ROUTE,
} from "./constants/routes";

const asyncPricing = asyncComponent(() => {
  return import("./containers/Dashboard/Pricing/Pricing");
});

const asyncLearn = asyncComponent(() => {
  return import("./containers/Presentation/PresentationC/Presentation");
});

const asyncReset = asyncComponent(() => {
  return import("./containers/Authentication/ResetPassword");
});

const asyncAbout = asyncComponent(() => {
  return import("./containers/About/About");
});

const asyncContact = asyncComponent(() => {
  return import("./containers/Contact/Contact");
});

const asyncMap = asyncComponent(() => {
  return import("./containers/Map/Map");
});

const asyncHollers = asyncComponent(() => {
  return import("./containers/Hollers/Hollers");
});

const asyncHoller = asyncComponent(() => {
  return import("./containers/Holler/Holler");
});

const asyncPreview = asyncComponent(() => {
  return import("./containers/Holler/Preview");
});

const asyncInvitation = asyncComponent(() => {
  return import("./containers/Member/Invitation");
});

const asyncPrivacy = asyncComponent(() => {
  return import("./containers/Privacy/Privacy");
});

const asyncTerms = asyncComponent(() => {
  return import("./containers/Terms/Terms");
});

const asyncRules = asyncComponent(() => {
  return import("./containers/Terms/Rules");
});

const asyncProfile = asyncComponent(() => {
  return import("./containers/Dashboard/UserProfile");
});

const asyncMyHollers = asyncComponent(() => {
  return import("./containers/Dashboard/MyHollers");
});

const asyncSignOut = asyncComponent(() => {
  return import("./containers/Dashboard/SignOut");
});

const asyncResetPassword = asyncComponent(() => {
  return import("./containers/Dashboard/UserResetPassword");
});

const asyncTransactions = asyncComponent(() => {
  return import("./containers/Dashboard/Transactions");
});

const asyncSignUp = asyncComponent(() => {
  return import("./containers/Presentation/PresentationD/Presentation");
});

class App extends Component {
  constructor(props) {
    super(props);
    this.onAuthChanged = this.onAuthChanged.bind(this);
    this.hideSnackBar = this.hideSnackBar.bind(this);
    this.unsubauth = auth.onAuthStateChanged(this.onAuthChanged);
  }

  componentWillUnmount() {
    if (this.unsubauth) {
      this.unsubauth();
      this.unsubauth = null;
    }
  }

  componentDidMount() {
    initGA("UA-148333988-1");
    initGTM("GTM-NNPSTMH");
    PageView();
  }

  onAuthChanged(user) {
    if (user) {
      // don't interfere with the registration flow
      if (!this.props.registerIsLoading) {
        this.props.onAuthenticated(user);
      }
    } else {
      this.props.onSetAuthUser(null);
    }
  }

  hideSnackBar() {
    this.props.onSetSnackBarMessage(null);
  }

  render() {
    let routes = (
      <Switch>
        <Route path={HOME_ROUTE} exact component={PresentationPage} />
        <Route path={LEARN_MORE_ROUTE} exact component={asyncLearn} />
        <Route path="/verify" exact component={asyncReset} />
        <Route path={SIGN_UP_ROUTE} exact component={asyncSignUp} />
        <Route path={CONTACT_ROUTE} exact component={asyncContact} />
        <Route path={PRIVACY_ROUTE} exact component={asyncPrivacy} />
        <Route path={TERMS_ROUTE} exact component={asyncTerms} />
        <Route path={RULES_ROUTE} exact component={asyncRules} />
        <Route path={ABOUT_ROUTE} exact component={asyncAbout} />
        <Route path={MAP_ROUTE} exact component={asyncMap} />
        <Route path={HOLLERS_ROUTE} exact component={asyncHollers} />
        <Route
          path="/invitation/:invitationId"
          exact
          component={asyncInvitation}
        />
        <Route path="/holler/:hollerId" exact component={asyncHoller} />
        <Route path="/preview/:hollerId" exact component={asyncPreview} />
        <Route path={PRICING_ROUTE} exact component={asyncPricing} />
        <AuthRoute path={PROFILE_ROUTE} exact component={asyncProfile} />
        <AuthRoute path={MY_HOLLERS_ROUTE} exact component={asyncMyHollers} />
        <AuthRoute path={SIGN_OUT_ROUTE} exact component={asyncSignOut} />
        <AuthRoute
          path={TRANSACTIONS_ROUTE}
          exact
          component={asyncTransactions}
        />
        <AuthRoute
          path={CHANGE_PASSWORD_ROUTE}
          exact
          component={asyncResetPassword}
        />
        <Redirect to="/" />
      </Switch>
    );

    return (
      <div>
        {routes}
        <ConsecutiveSnackbars />
        <HollerEditModal />
        <AuthenticationModal display={false} />
        <FilterModal />
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    registerIsLoading: state.ui.registerIsLoading,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onSetSnackBarMessage: (message) => dispatch(setSnackBarMessage(message)),
    onAuthenticated: (user) => dispatch(authenticated(user)),
    onSetAuthUser: (user) => dispatch(setAuthUser(user)),
  };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(App));
