import {
  AUTH_UI_START_LOADING,
  AUTH_UI_STOP_LOADING,
  REGISTER_UI_START_LOADING,
  REGISTER_UI_STOP_LOADING,
  HOLLER_UI_START_LOADING,
  HOLLER_UI_STOP_LOADING,
  HOLLER_UPDATE_START_LOADING,
  HOLLER_UPDATE_STOP_LOADING,
  COMMENT_UI_START_LOADING,
  COMMENT_UI_STOP_LOADING,
  LOGIN_UI_MODAL_DISPLAY,
  MENU_UI_DRAWER_DISPLAY,
  SET_SNACK_BAR_MESSAGE,
  HOLLER_UI_MODAL_DISPLAY,
  FILTER_UI_MODAL_DISPLAY,
  PLAN_CONFIRMATION_UI_MODAL_DISPLAY,
  TRANSACTIONS_UI_START_LOADING,
  TRANSACTIONS_UI_STOP_LOADING,
  MEMBERS_UI_START_LOADING,
  MEMBERS_UI_STOP_LOADING,
} from "../types/uiTypes";

export const authUIStartLoading = () => {
  return {
    type: AUTH_UI_START_LOADING,
  };
};

export const authUIStopLoading = () => {
  return {
    type: AUTH_UI_STOP_LOADING,
  };
};

export const registerUIStartLoading = () => {
  return {
    type: REGISTER_UI_START_LOADING,
  };
};

export const registerUIStopLoading = () => {
  return {
    type: REGISTER_UI_STOP_LOADING,
  };
};

export const hollerUIStartLoading = () => {
  return {
    type: HOLLER_UI_START_LOADING,
  };
};

export const hollerUIStopLoading = () => {
  return {
    type: HOLLER_UI_STOP_LOADING,
  };
};

export const hollerUpdateStartLoading = () => {
  return {
    type: HOLLER_UPDATE_START_LOADING,
  };
};

export const hollerUpdateStopLoading = () => {
  return {
    type: HOLLER_UPDATE_STOP_LOADING,
  };
};

export const membersUIStartLoading = () => {
  return {
    type: MEMBERS_UI_START_LOADING,
  };
};

export const membersUIStopLoading = () => {
  return {
    type: MEMBERS_UI_STOP_LOADING,
  };
};

export const transactionsUIStartLoading = () => {
  return {
    type: TRANSACTIONS_UI_START_LOADING,
  };
};

export const transactionsUIStopLoading = () => {
  return {
    type: TRANSACTIONS_UI_STOP_LOADING,
  };
};

export const commentUIStartLoading = () => {
  return {
    type: COMMENT_UI_START_LOADING,
  };
};

export const commentUIStopLoading = () => {
  return {
    type: COMMENT_UI_STOP_LOADING,
  };
};

export const setLoginModal = (display) => {
  return {
    type: LOGIN_UI_MODAL_DISPLAY,
    display,
  };
};

export const setMenuDrawer = (menuDrawer) => {
  return {
    type: MENU_UI_DRAWER_DISPLAY,
    menuDrawer,
  };
};

export const setSnackBarMessage = (snackBarMessage) => {
  return {
    type: SET_SNACK_BAR_MESSAGE,
    snackBarMessage,
  };
};

export const setHollerModal = (hollerDisplay) => {
  return {
    type: HOLLER_UI_MODAL_DISPLAY,
    hollerDisplay,
  };
};

export const setFilterModal = (filterDisplay) => {
  return {
    type: FILTER_UI_MODAL_DISPLAY,
    filterDisplay,
  };
};

export const setPlanModal = (planId) => {
  return {
    type: PLAN_CONFIRMATION_UI_MODAL_DISPLAY,
    planId,
  };
};
