import React, { useCallback, useEffect, useState } from "react";
// import Button from "@material-ui/core/Button";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import BubbleChartIcon from "@material-ui/icons/BubbleChart";
import { useSelector } from "react-redux";
// import { makeStyles } from "@material-ui/core/styles";

const Alert = props => {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
};

// const useStyles = makeStyles(theme => ({
//   root: {
//     width: "100%",
//     "& > * + *": {
//       marginTop: theme.spacing(2)
//     }
//   }
// }));

const defaultAnchor = {
  vertical: "top",
  horizontal: "center"
};

const ConsecutiveSnackbars = () => {
  const queueRef = React.useRef([]);
  const [open, setOpen] = React.useState(false);
  const [messageInfo, setMessageInfo] = React.useState(null);
  const [messageQueue, setMessageQueue] = useState({});
  const snackBarMessage = useSelector(state => state.ui.snackBarMessage);

  const processQueue = useCallback(() => {
    if (queueRef.current.length > 0) {
      const current = queueRef.current.shift();
      setMessageInfo(current);
      delete messageQueue[current.message];
      setMessageQueue(messageQueue);
      setOpen(true);
    } else {
      setOpen(false);
    }
  }, [messageQueue]);

  const addQueue = useCallback(
    snackBarMessage => {
      if (snackBarMessage && !messageQueue[snackBarMessage.message]) {
        messageQueue[snackBarMessage.message] = true;
        setMessageQueue(messageQueue);
        const {
          message,
          snackColor,
          anchorOrigin,
          autoHideDuration
        } = snackBarMessage;
        queueRef.current.push({
          message,
          snackColor,
          anchorOrigin,
          autoHideDuration,
          key: new Date().getTime()
        });
      }
      if (!open) {
        processQueue();
      }
    },
    [messageQueue, open, processQueue]
  );

  useEffect(() => {
    addQueue(snackBarMessage); // i don't give a hoot about changes to addQueue callback
    // if (open) {
    //   // immediately begin dismissing current message
    //   // to start showing new one
    //   setOpen(false);
    // } else {
    //   processQueue();
    // }
  }, [snackBarMessage]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleClose = () => {
    setOpen(false);
  };

  const handleExited = () => {
    processQueue();
  };

  return (
    <Snackbar
      open={open}
      anchorOrigin={messageInfo?.anchorOrigin || defaultAnchor}
      // anchorOrigin={{
      //   vertical: "bottom",
      //   horizontal: "left"
      // }}
      // autoHideDuration={autoHideDuration}
      autoHideDuration={messageInfo?.autoHideDuration || 2000}
      onClose={handleClose}
      onExited={handleExited}
    >
      <Alert
        icon={<BubbleChartIcon fontSize="inherit" />}
        onClose={handleClose}
        style={{
          background: messageInfo?.snackColor
        }}
      >
        {messageInfo?.message}
      </Alert>
    </Snackbar>
  );
};

export default ConsecutiveSnackbars;
