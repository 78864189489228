import React, { useEffect, useState } from "react";
// @material-ui/core components
import { withStyles, makeStyles, useTheme } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import FormControl from "@material-ui/core/FormControl";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import Slider from "@material-ui/core/Slider";

import useMediaQuery from "@material-ui/core/useMediaQuery";

import { useDispatch, useSelector } from "react-redux";
import { DEFAULT_CATEGORY } from "../../constants/categories";
import { FILTER_APPLY_PENDING } from "../../constants/messages";

// @material-ui/icons
import CloseIcon from "@material-ui/icons/Close";
import CircularProgress from "@material-ui/core/CircularProgress";

import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import Button from "components/CustomButtons/Button";
import { resetHollers, setHollerFilter } from "../../store/actions/holler";
import { setFilterModal, setSnackBarMessage } from "../../store/actions/ui";

import materialTheme from "../../constants/theme";
import { categories } from "../../constants/categories";

import signupPageStyle from "assets/jss/material-kit-pro-react/views/signupPageStyle";

const { COLORS } = materialTheme;

const { PRIMARY, INFO } = COLORS;

const styles = theme => ({
  root: {
    margin: 0,
    padding: theme.spacing(2)
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500]
  }
});

const DialogTitle = withStyles(styles)(props => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles(theme => ({
  root: {
    padding: theme.spacing(2)
    // display: "flex",
    // alignItems: "center"
  }
}))(MuiDialogContent);

// const DialogActions = withStyles(theme => ({
//   root: {
//     margin: 0,
//     padding: theme.spacing(1)
//   }
// }))(MuiDialogActions);

const useStyles = makeStyles(signupPageStyle);

const meta = {
  title: "Filter Hollers",
  subtitle: "Apply the following filters"
};

const PrettoSlider = withStyles({
  root: {
    color: PRIMARY,
    height: 8
  },
  thumb: {
    height: 24,
    width: 24,
    backgroundColor: "#fff",
    border: "2px solid currentColor",
    marginTop: -8,
    marginLeft: -12,
    "&:focus,&:hover,&$active": {
      boxShadow: "inherit"
    }
  },
  active: {},
  valueLabel: {
    left: "calc(-50% + 4px)"
  },
  track: {
    height: 8,
    borderRadius: 4
  },
  rail: {
    height: 8,
    borderRadius: 4
  }
})(Slider);

// const S5FrameOrSmaller = (width, height) => {
//   return height <= 650;
// };

// const resetRules = () => {
//   Object.keys(rules).forEach(ruleName => {
//     rules[ruleName].touched = false;
//   });
// };

const renderSelect = (value, defaultValue, icon, onChange, classes) => {
  return (
    <FormControl
      fullWidth
      className={classes.selectFormControl}
      style={{
        margin: "0 0 17px 0 !important",
        paddingTop: 27
      }}
    >
      {/*<InputLabel htmlFor="simple-select" className={classes.selectLabel}>*/}
      {/*  Category*/}
      {/*</InputLabel>*/}
      <Typography variant="overline" color={"textSecondary"}>
        Category
      </Typography>
      <Select
        MenuProps={{
          className: classes.selectMenu
        }}
        classes={{
          select: classes.select
        }}
        defaultValue={defaultValue}
        value={value}
        onChange={onChange}
        inputProps={{
          name: "categorySelect",
          id: "category-select"
        }}
      >
        <MenuItem
          disabled
          classes={{
            root: classes.selectMenuItem
          }}
        >
          Select a category
        </MenuItem>
        <MenuItem
          classes={{
            root: classes.selectMenuItem,
            selected: classes.selectMenuItemSelected
          }}
          value={DEFAULT_CATEGORY}
          key={DEFAULT_CATEGORY}
        >
          All
        </MenuItem>
        {categories.map(({ title, id }) => (
          <MenuItem
            classes={{
              root: classes.selectMenuItem,
              selected: classes.selectMenuItemSelected
            }}
            value={id}
            key={id}
          >
            {title}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default function FilterModal(props) {
  const theme = useTheme();
  const hollerUpdateIsLoading = useSelector(
    state => state.ui.hollerUpdateIsLoading
  );
  const filterDisplay = useSelector(state => state.ui.filterDisplay);
  const filter = useSelector(state => state.holler.filter);
  // const authUser = useSelector(state => state.auth.authUser);
  const classes = useStyles();
  const fullScreen = useMediaQuery(theme.breakpoints.down("xs"));
  const dispatch = useDispatch();
  const [category, setCategory] = useState(filter.category);
  const [range, setRange] = useState(filter.range);
  const [radius, setRadius] = useState(filter.radius);

  useEffect(() => {
    setCategory(filter.category);
    setRange(filter.range);
    setRadius(filter.radius);
  }, [filter]);

  // useEffect(() => {
  // }, [authUser]);

  const handleCategory = event => {
    setCategory(event.target.value);
  };

  const handleRange = (event, value) => {
    setRange(value);
  };

  const handleDistance = (event, value) => {
    setRadius(value);
  };

  const handleClose = () => {
    dispatch(setFilterModal(false));
    setCategory(DEFAULT_CATEGORY); // restore default
  };

  const handleSubmit = () => {
    dispatch(
      setSnackBarMessage({
        message: FILTER_APPLY_PENDING,
        snackColor: INFO,
        autoHideDuration: 3000
      })
    );
    dispatch(
      setHollerFilter({
        ...filter,
        range,
        category,
        radius
      })
    );
    dispatch(resetHollers(null));
    dispatch(setFilterModal(false));
  };

  const { title, subtitle } = meta;

  return (
    <div>
      <Dialog
        fullScreen={fullScreen}
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={filterDisplay}
        PaperProps={{
          style: {
            alignItems: "center"
          }
        }}
        fullWidth={true}
      >
        <DialogTitle id="customized-dialog-title" onClose={handleClose}>
          {/*{S5FrameOrSmaller(width, height) ? title : ""}*/}
          {""}
        </DialogTitle>
        <DialogContent
          style={{
            width: "100%"
          }}
        >
          <GridContainer justify="center">
            <GridItem xs={12} sm={12} md={10}>
              <Card
                className={classes.cardSignup}
                style={{
                  backgroundColor: "transparent",
                  boxShadow: "none",
                  padding: "0px 0px"
                }}
              >
                {/*{S5FrameOrSmaller(width, height) ? null : (*/}
                {/*  <h3 className={classes.cardTitle}>{title}</h3>*/}
                {/*)}*/}
                <h3 className={classes.cardTitle}>{title}</h3>
                <CardBody>
                  <GridContainer justify="center">
                    <GridItem xs={12} sm={12} md={12}>
                      <div className={classes.textCenter}>
                        <h5>{subtitle}</h5>
                      </div>
                      <form
                        className={classes.form}
                        style={{
                          maxHeight: 450
                        }}
                      >
                        <div
                          style={{
                            paddingTop: 27
                          }}
                        >
                          <Typography
                            variant="overline"
                            gutterBottom
                            color={"textSecondary"}
                          >
                            Number of days ago
                          </Typography>
                          <PrettoSlider
                            valueLabelDisplay="auto"
                            aria-label="date range"
                            defaultValue={90}
                            value={range}
                            step={1}
                            // marks
                            min={15}
                            max={90}
                            onChange={handleRange}
                          />
                        </div>

                        <div
                          style={{
                            paddingTop: 27
                          }}
                        >
                          <Typography
                            variant="overline"
                            gutterBottom
                            color={"textSecondary"}
                          >
                            Distance (kilometers)
                          </Typography>
                          <PrettoSlider
                            valueLabelDisplay="auto"
                            aria-label="distance range"
                            defaultValue={100}
                            value={radius}
                            step={1}
                            // marks
                            min={2}
                            max={100}
                            onChange={handleDistance}
                          />
                        </div>

                        {renderSelect(
                          category,
                          DEFAULT_CATEGORY,
                          "category",
                          handleCategory,
                          classes
                        )}

                        <div
                          className={classes.textCenter}
                          style={{
                            margin: "17px 0 17px 0"
                          }}
                        >
                          {hollerUpdateIsLoading ? (
                            <CircularProgress
                              className={classes.loadingIndicator}
                              color="secondary"
                              size={30}
                            />
                          ) : (
                            <Button
                              round
                              color="primary"
                              onClick={handleSubmit}
                            >
                              Apply Filter
                            </Button>
                          )}
                        </div>
                      </form>
                    </GridItem>
                  </GridContainer>
                </CardBody>
              </Card>
            </GridItem>
          </GridContainer>
        </DialogContent>
      </Dialog>
    </div>
  );
}
