/*!

=========================================================
* Material Kit PRO React - v1.7.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
// nodejs library to set properties for components
import PropTypes from "prop-types";
// core components
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";

// @material-ui icons
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";

import descriptionStyle from "assets/jss/material-kit-pro-react/views/presentationSections/descriptionStyle";

class SectionDescription extends React.Component {
  render() {
    const { classes } = this.props;
    return (
      <div
        className={classes.section}
        style={{
          paddingBottom: 0,
        }}
      >
        <div className={classes.container}>
          <GridContainer justify="center">
            <GridItem md={8} sm={8}>
              <h4 className={classes.description}>
                Holler Away empowers our community to shout out to nearby users
                wherever they are. Share deals, events, attractions, make new
                friends or even request for help from your community of fellow
                users!
              </h4>
            </GridItem>
          </GridContainer>
        </div>
      </div>
    );
  }
}

SectionDescription.propTypes = {
  classes: PropTypes.object,
};

export default withStyles(descriptionStyle)(SectionDescription);
