import {
  RESET_HOLLERS,
  RESET_LATEST_HOLLERS,
  RESET_FEATURED_HOLLERS,
  GET_LATEST_HOLLERS,
  GET_FEATURED_HOLLERS,
  GET_HOLLER,
  SET_EDIT_HOLLER,
  HOLLER_RATINGS,
  SET_HOLLER_FILTER,
  HOLLER_LATEST_SNAPSHOT,
  HOLLER_FEATURED_SNAPSHOT,
  HOLLER_LIVE_MAP_SELECT,
  SET_CONFIRM_FEATURE_HOLLER,
} from "../types/hollerTypes";
import { DEFAULT_CATEGORY } from "../../constants/categories";

const SINGAPORE = [1.363425, 103.8456233];

const initialState = {
  hollers: {},
  liveMapHollerSelect: {},
  latestHollers: {},
  featuredHollers: {},
  holler: null,
  editHoller: null,
  featureHollerCandidate: null,
  ratings: {},
  filter: {
    range: 30,
    category: DEFAULT_CATEGORY,
    radius: 10, // in kilometers,
    location: { latitude: SINGAPORE[0], longitude: SINGAPORE[1] },
  },
  hollerLatestSnapshot: null,
  hollerFeaturedSnapshot: null,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_LATEST_HOLLERS:
      return {
        ...state,
        latestHollers: { ...state.latestHollers, ...action.latestHollers },
      };
    case GET_FEATURED_HOLLERS:
      return {
        ...state,
        featuredHollers: {
          ...state.featuredHollers,
          ...action.featuredHollers,
        },
      };
    case SET_CONFIRM_FEATURE_HOLLER:
      return {
        ...state,
        featureHollerCandidate: action.featureHollerCandidate,
      };
    case RESET_HOLLERS:
      return {
        ...state,
        hollers: action.hollers,
      };
    case RESET_LATEST_HOLLERS:
      return {
        ...state,
        latestHollers: action.latestHollers,
      };
    case HOLLER_LIVE_MAP_SELECT:
      return {
        ...state,
        liveMapHollerSelect: action.liveMapHollerSelect,
      };
    case RESET_FEATURED_HOLLERS:
      return {
        ...state,
        featuredHollers: action.featuredHollers,
      };
    case GET_HOLLER:
      return {
        ...state,
        holler: action.holler,
      };
    case SET_EDIT_HOLLER:
      return {
        ...state,
        editHoller: action.editHoller,
      };
    case HOLLER_RATINGS:
      return {
        ...state,
        ratings: action.ratings,
      };
    case SET_HOLLER_FILTER:
      return {
        ...state,
        filter: action.filter,
      };
    case HOLLER_LATEST_SNAPSHOT:
      return {
        ...state,
        hollerLatestSnapshot: action.hollerLatestSnapshot,
      };
    case HOLLER_FEATURED_SNAPSHOT:
      return {
        ...state,
        hollerFeaturedSnapshot: action.hollerFeaturedSnapshot,
      };
    default:
      return state;
  }
};

export default reducer;
