const firebaseConfigProd = {
  apiKey: "AIzaSyC1jEfI9FZUD4toZBcyeOPXHS5VetwC-Uc",
  authDomain: "holler-away-rn.firebaseapp.com",
  databaseURL: "https://holler-away-rn.firebaseio.com",
  projectId: "holler-away-rn",
  storageBucket: "holler-away-rn.appspot.com",
  messagingSenderId: "234317303864",
  appId: "1:234317303864:web:efdcb1a3b77746a5"
};

const firebaseConfigDev = {
  apiKey: "AIzaSyCkxW7YcMHLzQthav3XW0Tc4Hux3BtEmDA",
  authDomain: "holler-away-rn-dev.firebaseapp.com",
  databaseURL: "https://holler-away-rn-dev.firebaseio.com",
  projectId: "holler-away-rn-dev",
  storageBucket: "holler-away-rn-dev.appspot.com",
  messagingSenderId: "1034785909024",
  appId: "1:1034785909024:web:7d43bbda0c430b9578cf45"
};

export default process.env.NODE_ENV === "development"
  ? firebaseConfigDev
  : firebaseConfigProd;
