import { store } from "../../shared/firebase";
import {
  RESET_MEMBERS,
  GET_MEMBERS,
  MEMBERS_LATEST_SNAPSHOT,
} from "../types/membersTypes";
import { membersUIStartLoading, membersUIStopLoading } from "./ui";

const profileCollection = store.collection("profiles");

export const queryNewMembers = (prevSnapshot = null, pageSize = 12) => {
  try {
    let query = profileCollection.orderBy("createdAt", "desc");
    query = prevSnapshot ? query.startAfter(prevSnapshot) : query;
    query = query.limit(pageSize);
    return query;
  } catch (err) {
    // note: stringify to reveal composite index link for complex queries
    console.log("err", JSON.stringify(err));
  }
};

export const getPaginatedMembers = (pageSize = 12) => {
  return async (dispatch, getState) => {
    try {
      dispatch(membersUIStartLoading());
      const prevSnapshot = getState().member.membersSnapshot;
      const documents = await queryNewMembers(prevSnapshot, pageSize).get();
      const members = {};
      const lastSnapshot = !documents.empty
        ? documents.docs[documents.docs.length - 1]
        : null;
      const docs = documents.docs;
      docs.forEach((doc) => {
        const data = doc.data();
        members[doc.id] = {
          ...data,
          docId: doc.id,
        };
      });
      dispatch(membersUIStopLoading());
      dispatch(setMembersSnapshot(lastSnapshot));
      dispatch(setMembers(members));
    } catch (error) {
      console.log("get members err", JSON.stringify(error));
      dispatch(membersUIStopLoading());
    }
  };
};

export const resetMembers = (members) => {
  return {
    type: RESET_MEMBERS,
    members: members,
  };
};

export const setMembers = (members) => {
  return {
    type: GET_MEMBERS,
    members: members,
  };
};

export const setMembersSnapshot = (membersSnapshot) => {
  return {
    type: MEMBERS_LATEST_SNAPSHOT,
    membersSnapshot: membersSnapshot,
  };
};
