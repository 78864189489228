export const AUTH_UI_START_LOADING = "AUTH_UI_START_LOADING";
export const AUTH_UI_STOP_LOADING = "AUTH_UI_STOP_LOADING";
export const HOLLER_UI_START_LOADING = "HOLLER_UI_START_LOADING";
export const HOLLER_UI_STOP_LOADING = "HOLLER_UI_STOP_LOADING";
export const HOLLER_UPDATE_START_LOADING = "HOLLER_UPDATE_START_LOADING";
export const HOLLER_UPDATE_STOP_LOADING = "HOLLER_UPDATE_STOP_LOADING";
export const COMMENT_UI_START_LOADING = "COMMENT_UI_START_LOADING";
export const COMMENT_UI_STOP_LOADING = "COMMENT_UI_STOP_LOADING";
export const LOGIN_UI_MODAL_DISPLAY = "LOGIN_UI_MODAL_DISPLAY";
export const MENU_UI_DRAWER_DISPLAY = "MENU_UI_DRAWER_DISPLAY";
export const SET_SNACK_BAR_MESSAGE = "SET_SNACK_BAR_MESSAGE";
export const REGISTER_UI_START_LOADING = "REGISTER_UI_START_LOADING";
export const REGISTER_UI_STOP_LOADING = "REGISTER_UI_STOP_LOADING";
export const HOLLER_UI_MODAL_DISPLAY = "HOLLER_UI_MODAL_DISPLAY";
export const FILTER_UI_MODAL_DISPLAY = "FILTER_UI_MODAL_DISPLAY";
export const PLAN_CONFIRMATION_UI_MODAL_DISPLAY =
  "PLAN_CONFIRMATION_UI_MODAL_DISPLAY";
export const TRANSACTIONS_UI_START_LOADING = "TRANSACTIONS_UI_START_LOADING";
export const TRANSACTIONS_UI_STOP_LOADING = "TRANSACTIONS_UI_STOP_LOADING";
export const MEMBERS_UI_START_LOADING = "MEMBERS_UI_START_LOADING";
export const MEMBERS_UI_STOP_LOADING = "MEMBERS_UI_STOP_LOADING";
