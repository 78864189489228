export const HOME_ROUTE = "/";
export const LOGIN_ROUTE = "/login";
export const PROFILE_ROUTE = "/profile";
export const TRANSACTIONS_ROUTE = "/transactions";
export const MAP_ROUTE = "/map";
export const HOLLERS_ROUTE = "/hollers";
export const MY_HOLLERS_ROUTE = "/myhollers";
export const LEARN_MORE_ROUTE = "/learn";
export const SIGN_OUT_ROUTE = "/signout";
export const CHANGE_PASSWORD_ROUTE = "/changepassword";

export const CONTACT_ROUTE = "/contact";
export const ABOUT_ROUTE = "/about";
export const PRIVACY_ROUTE = "/privacy";
export const TERMS_ROUTE = "/terms";
export const RULES_ROUTE = "/rules";
export const SIGN_UP_ROUTE = "/signup";
export const PRICING_ROUTE = "/pricing";
