/*eslint-disable*/
import React, { useEffect } from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// core components
import Header from "components/Header/Header";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import Parallax from "components/Parallax/Parallax";
import Button from "components/CustomButtons/Button";
import Footer from "components/Footer/Footer";
// sections for this page
import HeaderLinks from "components/Header/HeaderLinks";
import ReactPlayer from "react-player";
import SectionProducts from "./Sections/SectionProducts";
import SectionLatestOffers from "./Sections/SectionLatestOffers";
import SectionPricing from "./Sections/SectionPricing";
import SectionDescription from "./Sections/SectionDescription";
// @material-ui/core components
import { makeStyles, useTheme } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import InfoIcon from "@material-ui/icons/Info";
import ListItem from "@material-ui/core/ListItem";
import CloudDownloadIcon from "@material-ui/icons/CloudDownloadOutlined";

import styles from "assets/jss/material-kit-pro-react/views/ecommerceStyle";
import { Helmet } from "react-helmet";
// images
// import hollerAwayBG from "assets/img/hollerawaybg4.jpg";
import materialTheme from "../../../constants/theme";
import {
  ABOUT_ROUTE,
  CONTACT_ROUTE,
  MAP_ROUTE,
  MY_HOLLERS_ROUTE,
  PRICING_ROUTE,
  PRIVACY_ROUTE,
  RULES_ROUTE,
  SIGN_UP_ROUTE,
  TERMS_ROUTE,
} from "../../../constants/routes";
import { Event } from "../../../components/Tracking";
import {
  EXPLORE_MORE_CATEGORY,
  GET_STARTED_ACTION,
  GET_STARTED_LABEL,
  HEADER_SIGN_UP_ACTION,
  HEADER_SIGN_UP_LABEL,
  SIGN_UP_CATEGORY,
} from "../../../constants/ga";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import { useHistory, useLocation } from "react-router-dom";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { PAGE_ID, theme } from "../../../shared/constants";
import MessengerCustomerChat from "react-messenger-customer-chat";
import AuthFixedPlugin from "../../Authentication/AuthFixedPlugin";
import { useSelector, useDispatch } from "react-redux";
import { setLoginModal, setMenuDrawer } from "../../../store/actions/ui";
import { setNextNavRoute } from "../../../store/actions/nav";
import { isMobileDevice } from "../../../shared/utility";

const useStyles = makeStyles(styles);

const meta = {
  title: "Holler Away | Holler, Mingle & Engage with People Around You",
  description:
    "Holler to people around you. | Share deals, events, alerts or make new friends!",
};

export default function Presentation() {
  const history = useHistory();
  const dispatch = useDispatch();
  const authUser = useSelector((state) => state.auth.authUser);
  const location = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
  }, [history]);
  useEffect(() => {
    const { pathname } = location;
    if (pathname === SIGN_UP_ROUTE) {
      dispatch(setLoginModal(true));
      dispatch(setMenuDrawer(false));
      dispatch(setNextNavRoute(MY_HOLLERS_ROUTE));
      Event(SIGN_UP_CATEGORY, HEADER_SIGN_UP_ACTION, HEADER_SIGN_UP_LABEL);
    }
  }, [dispatch, location]);

  const renderHeaderDisplayB = (classes) => {
    return (
      <GridContainer>
        <GridItem
          xs={12}
          sm={7}
          md={7}
          className={classNames(classes.mrAuto, classes.textLeft)}
        >
          <h1 className={classes.title}>Holler on the go</h1>
          <h4
            style={{
              color: materialTheme.COLORS.WHITE,
              width: "85%",
            }}
          >
            If you spot something interesting, holler out to people around you.
            Just snap a photo and holler. It's that easy!
          </h4>
          <br />
          <div>
            <Button
              href="#download"
              rel="noopener noreferrer"
              // target="_blank"
              color="white"
              simple
              size="lg"
            >
              <CloudDownloadIcon /> Download Now
            </Button>

            <Button
              color="rose"
              size="lg"
              round
              onClick={() => {
                history.push(MAP_ROUTE);
                Event(
                  EXPLORE_MORE_CATEGORY,
                  GET_STARTED_ACTION,
                  GET_STARTED_LABEL
                );
              }}
            >
              <NavigateNextIcon /> Try Now!
            </Button>
          </div>
        </GridItem>
      </GridContainer>
    );
  };

  const classes = useStyles();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("xs"));
  return (
    <div>
      <Helmet>
        <title>{meta.title}</title>
        <meta name="description" content={meta.description} />
      </Helmet>
      <Header
        brand="Holler Away"
        links={<HeaderLinks dropdownHoverColor="rose" />}
        fixed
        color="transparent"
        changeColorOnScroll={{
          height: 300,
          color: "rose",
        }}
      />
      <Parallax
        image={
          isMobileDevice(navigator)
            ? require("assets/img/hollerawaybg2.jpg")
            : require("assets/img/hollerawaybg4.jpg")
        }
        filter="darkMild"
        small={!fullScreen}
      >
        <div className={classes.container}>{renderHeaderDisplayB(classes)}</div>
      </Parallax>

      <div className={classNames(classes.main, classes.mainRaised)}>
        {/*{!fullScreen && <SectionDescription />}*/}
        {!fullScreen && (
          <div className={classes.video}>
            <ReactPlayer
              url="https://www.youtube.com/watch?v=2RKtzNPgU_s"
              playing
              muted
              loop
              controls
            />
          </div>
        )}
        <SectionLatestOffers />
        <SectionProducts />
      </div>
      <SectionPricing />
      {!authUser && <AuthFixedPlugin />}
      <Footer
        theme="white"
        content={
          <div>
            <div className={classes.left}>
              <a href="/" className={classes.footerBrand}>
                Holler Away Pte. Limited
              </a>
            </div>
            <div className={classes.pullCenter}>
              <List className={classes.list}>
                <ListItem className={classes.inlineBlock}>
                  <a href={ABOUT_ROUTE} className={classes.block}>
                    About us
                  </a>
                </ListItem>
                <ListItem className={classes.inlineBlock}>
                  <a href={PRICING_ROUTE} className={classes.block}>
                    Pricing
                  </a>
                </ListItem>
                <ListItem className={classes.inlineBlock}>
                  <a href={CONTACT_ROUTE} className={classes.block}>
                    Contact Us
                  </a>
                </ListItem>
                <ListItem className={classes.inlineBlock}>
                  <a href={TERMS_ROUTE} className={classes.block}>
                    Terms and Conditions
                  </a>
                </ListItem>
                <ListItem className={classes.inlineBlock}>
                  <a href={RULES_ROUTE} className={classes.block}>
                    Community Rules
                  </a>
                </ListItem>
                <ListItem className={classes.inlineBlock}>
                  <a href={PRIVACY_ROUTE} className={classes.block}>
                    Privacy Policy
                  </a>
                </ListItem>
              </List>
            </div>
            <div className={classes.rightLinks}>
              <ul>
                <li>
                  <Button
                    href="https://twitter.com/HollerAway"
                    rel="noopener noreferrer"
                    target="_blank"
                    color="twitter"
                    justIcon
                    simple
                  >
                    <i className="fab fa-twitter" />
                  </Button>
                </li>
                <li>
                  <Button
                    href="https://www.facebook.com/hollerawayapp"
                    rel="noopener noreferrer"
                    target="_blank"
                    color="facebook"
                    justIcon
                    simple
                  >
                    <i className="fab fa-facebook" />
                  </Button>
                </li>
                <li>
                  <Button
                    href="https://www.linkedin.com/company/holleraway"
                    rel="noopener noreferrer"
                    target="_blank"
                    color="linkedin"
                    justIcon
                    simple
                  >
                    <i className="fab fa-linkedin-in" />
                  </Button>
                </li>
              </ul>
            </div>
          </div>
        }
      />
      <MessengerCustomerChat
        pageId={PAGE_ID}
        appId=""
        htmlRef=""
        themeColor={materialTheme.COLORS.PRIMARY}
        loggedInGreeting="Hi there! Please let us know how can we help you?"
      />
    </div>
  );
}
