import {
  GET_NOTIFICATIONS,
  GET_NOTIFICATION_TIMESTAMP
} from "../types/notificationTypes";

const initialState = {
  notifications: {},
  timestamp: null
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_NOTIFICATIONS:
      return {
        ...state,
        notifications: action.notifications
      };
    case GET_NOTIFICATION_TIMESTAMP:
      return {
        ...state,
        timestamp: action.timestamp
      };
    default:
      return state;
  }
};

export default reducer;
