import materialTheme from "./theme";

export const DEFAULT_CATEGORY = "all";
export const DEFAULT_CATEGORY_ALT = "others";

export const categories = [
  {
    id: "meals",
    title: "Meals",
    icon: "restaurant",
    color: materialTheme.COLORS.INFO,
    colorTag: "info",
    placeHolder: "Share any yummy recommendations in this part of town! 😋",
  },
  {
    id: "shopping",
    title: "Shopping",
    icon: "shopping_cart",
    color: materialTheme.COLORS.LABEL,
    colorTag: "rose",
    placeHolder:
      "Spot any good deals in town? Share it with folks around here! 🤩",
  },
  {
    id: "social",
    title: "Social",
    icon: "people",
    color: materialTheme.COLORS.BLUE,
    colorTag: "blue",
    placeHolder: "Looking for friends in this area? Introduce yourself! 🤠",
  },
  {
    id: "dating",
    title: "Dating",
    icon: "favorite",
    color: materialTheme.COLORS.PINK,
    colorTag: "pink",
    placeHolder:
      "Talk about your ideal partner. You might find a match in this area! 😉",
  },
  {
    id: "jobs",
    title: "Jobs",
    icon: "business_center",
    color: materialTheme.COLORS.DARK_PURPLE,
    colorTag: "darkPurple",
    placeHolder:
      "Looking to hire or be hired? Share any jobs to folks in this area. 🤓",
  },
  {
    id: "events",
    title: "Events",
    icon: "local_activity",
    color: materialTheme.COLORS.WARNING,
    colorTag: "warning",
    placeHolder:
      "Rock concerts or road shows here? Let others join in the fun! 😎",
  },
  {
    id: "hotel",
    title: "Hotel",
    icon: "hotel",
    color: materialTheme.COLORS.PRIMARY,
    colorTag: "primary",
    placeHolder:
      "Share any good accommodations here for weary travellers in town! 😴",
  },
  {
    id: "services",
    title: "Services",
    icon: "sentiment_very_satisfied",
    color: materialTheme.COLORS.SUCCESS,
    colorTag: "success",
    placeHolder:
      "Promote any good services to folks here! Just nothing illegal or sexual. 🤗",
  },
  {
    id: "attractions",
    title: "Attractions",
    icon: "spa",
    color: materialTheme.COLORS.GREEN_DARK,
    colorTag: "greenDark",
    placeHolder:
      "Any interesting attractions here? Introduce them to newbies in town! 😍",
  },
  {
    id: "warnings",
    title: "Warnings",
    icon: "warning",
    color: materialTheme.COLORS.ERROR,
    colorTag: "error",
    placeHolder: "Be a hero! Alert others about any hazards in this area! 🧐",
  },
  {
    id: "help",
    title: "Help",
    icon: "emoji_people",
    color: materialTheme.COLORS.INDIGO_DEEP,
    colorTag: "indigoDeep",
    placeHolder:
      "Need help? Holler out to folks in this area. There might be a kind soul nearby. 😇",
  },
  {
    id: "others",
    title: "Others",
    icon: "question_answer",
    color: materialTheme.COLORS.TEAL_DEEP,
    colorTag: "tealDeep",
    placeHolder: "What's interesting here? Share it with people around you. 😺",
  },
];

export const categoryForId = (categoryId) => {
  const filter = categoryId ? categoryId : "others";
  return categories.filter(({ id }) => id === filter)[0];
};
