import {
  REV_GEO_CODE,
  RESET_ADDRESS,
  USER_SELECT_LOCATION,
  USER_SELECT_PLACE,
  SELECT_MARKER,
  REGION_SWITCH,
} from "../types/placeTypes";

const initialState = {
  address: null,
  selectedPlace: null,
  selectedLocation: null,
  selectedMarker: null,
  region: {},
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case REV_GEO_CODE:
      return {
        ...state,
        address: action.address,
      };
    case RESET_ADDRESS:
      return {
        ...state,
        address: null,
        selectedPlace: null,
      };
    case USER_SELECT_LOCATION:
      return {
        ...state,
        selectedLocation: action.selectedLocation,
      };
    case USER_SELECT_PLACE:
      return {
        ...state,
        selectedPlace: action.selectedPlace,
      };
    case SELECT_MARKER:
      return {
        ...state,
        selectedMarker: action.selectedMarker,
      };
    case REGION_SWITCH:
      return {
        ...state,
        region: action.region,
      };
    default:
      return state;
  }
};

export default reducer;
