export const ACCOUNT_SUSPENDED =
  "Sorry, your account has been suspended. You may not create any hollers. Please contact support@holleraway.com for any questions.";
export const NAME_ERROR = "Enter a display name";
// export const HERO_WELCOME =
//   "Hurray! 🎊 🎉 Thank you for taking the first step with us! You have a daily limit of 2 hollers! This daily limit will be increase by 1 (up to a maximum of 10) every time your shoutout is featured by out staff. Start Hollering Away!";
export const HERO_WELCOME =
  "Hurray! 🎊 🎉 Thank you for taking the first step with us! We look forward to what you have to share. Start Hollering Away!";
export const PASSWORD_ERROR = (minLength) =>
  `Your password needs at least ${minLength} characters`;
export const PASSWORD_SHORT_ERROR = (minLength) =>
  `At least ${minLength} characters`;
export const PASSWORD_LOGIN_ERROR = "Please enter your password";
export const EMAIL_ERROR = "Please enter your email address";
export const ADDRESS_PENDING = "Finding address, please wait.";
export const LOCATION_PENDING = "Finding your current location, please wait.";
export const LOCATION_ERROR =
  "Unable to find your current location. Please grant us permission to access your location!";
export const HOLLER_RESULTS_PENDING =
  "Searching for hollers in this area. Please wait.";
export const HOLLER_LIVE_MAP_SELECTED_PENDING =
  "Fetching holler details. Please wait.";
export const HOLLER_RESULTS = (resultCount) =>
  `${resultCount} hollers found in this area! Click on the holler to view the details.`;
export const HOLLER_NO_RESULTS =
  "It's lonely here. 😭 Be a pioneer. Be the first to holler here! 👨‍🚀";
export const AUTH_PENDING = "Authenticating... Please wait.";
export const AUTH_ERROR =
  "Failed to login. Please ensure your email and password are correct.";
export const AUTH_SUCCESS = (displayName) => `Signed in as ${displayName}`;
export const AUTH_SIGN_OUT_PENDING = "Signing out... Please Wait.";
export const AUTH_SIGN_OUT_SUCCESS = "Signed out successfully!";
export const AUTH_SIGN_OUT_ERROR = "Failed to sign out. Please try again.";

export const REGISTER_SIGN_UP_PENDING = "Creating account... Please Wait.";
// export const REGISTER_SIGN_UP_SUCCESS = "Created Account Successfully!";
export const REGISTER_SIGN_UP_ERROR =
  "Failed to create account. Please try again.";

export const PROFILE_UPDATE_PENDING = "Updating Profile... Please Wait.";
export const PROFILE_UPDATE_SUCCESS = "Profile Updated Successfully!";
export const PROFILE_UPDATE_ERROR =
  "Failed to update profile. Please try again.";

export const EMAIL_INSTRUCTIONS_PENDING =
  "Sending verification instructions... Please Wait.";
export const EMAIL_INSTRUCTIONS_SUCCESS = (email) =>
  `Email sent to ${email}. Please check your email for details.`;
export const EMAIL_INSTRUCTIONS_ERROR = (email) =>
  `There was a problem sending the verification instructions to ${email}. Please try again.`;

export const PROFILE_DELETE_TITLE = "Delete Account?";
export const PROFILE_DELETE_MESSAGE =
  "This action will delete your personal information and is not reversible. If you also wish to delete your hollers, please first go to your hollers page and delete them individually before deleting your profile.";

export const PROFILE_DELETE_PENDING = "Deleting Profile... Please Wait.";
export const PROFILE_DELETE_SUCCESS = "Profile Delete Successfully!";
export const PROFILE_DELETE_ERROR =
  "Failed to delete profile. Please log out and log back in before trying again. If this also fails, please contact support@holleraway.com to request for profile deletion.";

export const HOLLER_EXCEED_LIMIT =
  "You've exceeded your daily limit. 😭 Come back tomorrow or purchase more!";

export const HOLLER_UPDATE_PENDING = "Updating Holler... Please Wait.";
export const HOLLER_UPDATE_SUCCESS = "Holler Updated Successfully!";
export const HOLLER_UPDATE_ERROR = "Failed to update holler. Please try again.";
export const HOLLER_UPDATE_MEDIA_ERROR =
  "Failed to update holler. You cannot modify images for hollers that already contain video.";

export const HOLLER_CREATE_PENDING = "Creating Holler... Please Wait.";
export const HOLLER_CREATE_SUCCESS = "Holler Created Successfully!";
export const HOLLER_CREATE_ERROR =
  "Failed to created holler. Please try again.";

export const HOLLER_DELETE_PENDING = "Deleting Holler... Please Wait.";
export const HOLLER_DELETE_SUCCESS = "Holler Deleted Successfully!";
export const HOLLER_DELETE_ERROR = "Failed to delete holler. Please try again.";

export const HOLLER_FEATURE_PENDING = "Featuring Holler... Please Wait.";
export const HOLLER_FEATURE_SUCCESS = "Holler Featured Successfully!";
export const HOLLER_FEATURE_ERROR =
  "Failed to feature holler. Please try again.";

export const HOLLER_BLOCKED =
  "This Holler has been blocked by the administrator for inappropriate content. Please contact support@holleraway.com for any questions.";

export const COMMENT_ADD_PENDING = "Sending Comment... Please Wait.";
export const COMMENT_ADD_SUCCESS = "Comment sent successfully!";
export const COMMENT_ADD_ERROR = "Failed to send comment. Please try again.";

export const HOLLER_DELETE_TITLE = (title) => `Delete "${title}"?`;
export const HOLLER_DELETE_MESSAGE = (title) =>
  `This action will delete "${title}" and is not reversible.`;

export const HOLLER_FEATURE_TITLE = (title) => `Feature "${title || ""}"? 🤩`;
export const HOLLER_FEATURE_MESSAGE = (title, featurePoints) =>
  featurePoints <= 0
    ? `This action will display "${
        title || ""
      }" on Holler Away website's front page and app's featured listings. You have insufficient feature points to perform this action. Do you wish to buy feature points?`
    : `This action will display "${
        title || ""
      }" on Holler Away website's front page and app's featured listings. This will cost 1 feature point. You have ${featurePoints} feature points available.`;

export const EMAIL_RESET_PASSWORD_PENDING =
  "Sending reset password instructions... Please Wait.";
export const EMAIL_RESET_PASSWORD_SUCCESS = (email) =>
  `Reset password instructions sent to ${email}. Please check your email for details.`;
export const EMAIL_RESET_PASSWORD_ERROR = (email) =>
  `There was a problem sending the reset password instructions to ${email}. Please try again.`;

export const EDIT = "Edit";
export const VIEW = "View";
export const DELETE = "Delete";
export const FEATURE = "Feature";

export const NAME_BILLING_ERROR = "Please enter your name!";

export const HOLLER_TITLE_ERROR = "Enter a title for your holler";
export const HOLLER_TITLE_SHORT_ERROR = "Say something! Or just say hi... 🤡";
export const HOLLER_DESC_ERROR = "Enter a description for your holler";
export const CHARACTER_LIMIT = (limit) =>
  `Please keep to ${limit} characters limit.`;

export const NO_LOCATION = "Location not available";

export const MAP_TIP = "Tap on the map to select a location to holler!";
export const FILTER_APPLY_PENDING = "Applying Filter... Please Wait.";

export const REVERSE_GEO_PENDING = "Getting location details... Please Wait.";
export const REVERSE_GEO_SUCCESS = "Location details retrieved successfully!";
export const REVERSE_GEO_ERROR =
  "Failed to retrieve location details. Please try again.";

export const COMMENT_ERROR = "Please enter a comment";

export const LOAD_LATEST = "Loading Latest Hollers... Please Wait";
export const LOAD_NEAR_ME = "Loading Hollers Near You... Please Wait";

export const PROCESSING_IMAGES = "Processing Images. Please Wait...";
export const IMAGE_UPLOAD_INSTRUCTION =
  "Max file size: 5mb, 6 photos only.Accepted: jpg|jpeg|png.";
export const IMAGE_ORIENTATION_ADVISORY =
  "Don't worry if your image previews are not rotated correctly. Images will be automatically rotated to the correct orientation upon submission.";

export const PAYMENT_SUCCESS = (amount, planTitle) =>
  `Thank you for your purchase! You have paid ${amount} for the ${planTitle} and will receive a confirmation email shortly. Please reach out to support@holleraway.com or message us on Facebook if there is a problem with your purchase.`;
export const PAYMENT_ERROR = (planTitle) =>
  `Failed to process payment for ${planTitle}. Please try again or contact support@holleraway.com.`;
