import materialTheme from "./theme";

export const ADMIN_TYPE = "admin";
export const BUSINESS_TYPE = "business";
export const USER_TYPE = "user";

export const types = [
  {
    id: ADMIN_TYPE,
    title: "Staff Picks",
    color: materialTheme.COLORS.PRIMARY,
    colorTag: "primary"
  },
  {
    id: BUSINESS_TYPE,
    title: "Merchant",
    color: materialTheme.COLORS.GREEN_DARK,
    colorTag: "greenDark"
  },
  {
    id: USER_TYPE,
    title: "Community",
    color: materialTheme.COLORS.INDIGO_DEEP,
    colorTag: "indigoDeep"
  }
];

export const typesForId = typeId => {
  const filter = typeId ? typeId : USER_TYPE;
  return types.filter(({ id }) => id === filter)[0];
};
