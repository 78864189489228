/*eslint-disable*/
import React, { useEffect, useState } from "react";
import moment from "moment";
import customSelectStyle from "assets/jss/material-kit-pro-react/customSelectStyle";
import customCheckboxRadioSwitchStyle from "assets/jss/material-kit-pro-react/customCheckboxRadioSwitchStyle";
import tooltipsStyle from "assets/jss/material-kit-pro-react/tooltipsStyle";
// nodejs library to set properties for components
import PropTypes from "prop-types";
import classNames from "classnames";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Tooltip from "@material-ui/core/Tooltip";
import CircularProgress from "@material-ui/core/CircularProgress";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
// import IconButton from "@material-ui/core/IconButton";
//  import MoreHorizIcon from "@material-ui/icons/MoreHoriz";

// @material-ui/icons
// import MoreIcon from "@material-ui/icons/More";
import { blackColor, hexToRgb } from "assets/jss/material-kit-pro-react";
import styles from "assets/jss/material-dashboard-pro-react/customCheckboxRadioSwitch";
import Button from "components/Dashboard/CustomButtons/Button";
import CustomInput from "components/CustomInput/CustomInput";

import materialTheme from "../../constants/theme";
import { useDispatch, useSelector } from "react-redux";
import { MY_HOLLERS_ROUTE } from "../../constants/routes";
import { Event, pushDataLayer } from "../../components/Tracking";
import { FB_LARGE, profileImg } from "../../shared/avatar";
import {
  SIGN_UP_CATEGORY,
  SUBMIT_SIGN_UP_CONVERSION,
  SUBMIT_SIGN_UP_SIDE_ACTION,
  SUBMIT_SIGN_UP_SIDE_LABEL,
} from "../../constants/ga";
import isEmpty from "lodash.isempty";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useHistory } from "react-router-dom";
import { emailDomains, NOT_LISTED } from "../../variables/general";
import { checkValidity } from "../../shared/utility";
import InputAdornment from "@material-ui/core/InputAdornment";
import Icon from "@material-ui/core/Icon";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import { EMAIL_ERROR, PASSWORD_SHORT_ERROR } from "../../constants/messages";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Check from "@material-ui/icons/Check";
import { registerUIStartLoading } from "../../store/actions/ui";
import { signUp } from "../../store/actions/auth";
import { setNextNavRoute } from "../../store/actions/nav";
import {
  getPaginatedMembers,
  setMembersSnapshot,
  resetMembers,
} from "../../store/actions/members";

const rules = {
  email: {
    required: true,
    isEmail: true,
    touched: false,
    errorMsg: EMAIL_ERROR,
  },
  password: {
    required: true,
    minLength: 6,
    touched: false,
    errorMsg: PASSWORD_SHORT_ERROR(6),
  },
};

const useStyles = makeStyles((theme) => ({
  ...styles,
  ...customSelectStyle,
  ...customCheckboxRadioSwitchStyle,
  ...tooltipsStyle,
  buttonContainerCustom: {
    marginBottom: 16,
  },
  button: {
    width: "90%",
  },
  click: {
    cursor: "pointer",
  },
  fixed: {
    // top: 240,
    "@media (max-width: 991px)": {
      top: 140,
    },
  },
  gridListContainer: {
    width: "100% !important",
    paddingLeft: "8px !important",
    paddingRight: "8px !important",
  },
  gridList: {
    // flexWrap: "nowrap",
    height: 360,
  },
  loading: {
    margin: 8,
  },
  dropDownMobile: {
    maxHeight: "60vh",
    overflow: "auto",
  },
  formControlCustom: {
    paddingTop: 14,
  },
  labelRootCustom: {
    top: 0,
  },
  img: {
    height: "50px !important",
    width: "50px !important",
    objectFit: "cover",
    marginRight: "5% !important",
    marginBottom: "5% !important",
    float: "left !important",
  },
  imgRaised: {
    boxShadow:
      "0 5px 15px -8px rgba(" +
      hexToRgb(blackColor) +
      ", 0.24), 0 8px 10px -5px rgba(" +
      hexToRgb(blackColor) +
      ", 0.2)",
  },
  imgRounded: {
    borderRadius: "6px !important",
  },
}));

const resetRules = () => {
  Object.keys(rules).forEach((ruleName) => {
    rules[ruleName].touched = false;
  });
};

const enableRegisterBtn = (email, password, checked) => {
  return (
    checkValidity(email, rules.email) &&
    checkValidity(password, rules.password) &&
    checked.indexOf(1) !== -1
  );
};

const handleTextChange = (name, value, setField) => {
  const rule = rules[name];
  rule.touched = true;
  setField(value);
};

const renderCheckBoxField = (checked, label, link, handleToggle, classes) => {
  return (
    <FormControlLabel
      classes={{
        label: classes.label,
      }}
      control={
        <Checkbox
          tabIndex={-1}
          onClick={() => handleToggle(1)}
          checkedIcon={<Check className={classes.checkedIcon} />}
          icon={<Check className={classes.uncheckedIcon} />}
          classes={{
            checked: classes.checked,
            root: classes.checkRoot,
          }}
          size="small"
          checked={checked.indexOf(1) !== -1}
        />
      }
      label={
        <span>
          I agree to the{" "}
          <a href={link} target="_blank" rel="noopener noreferrer">
            {label}
          </a>
          .
        </span>
      }
    />
  );
};

// const useStyles = makeStyles(styles);

export default function AuthFixedPlugin(props) {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("xs"));
  // start
  const dispatch = useDispatch();
  const history = useHistory();
  const [domain, setDomain] = useState(emailDomains[0]);
  const registerIsLoading = useSelector((state) => state.ui.registerIsLoading);
  const [email, setEmail] = useState(null);
  const [username, setUserName] = useState(null);
  const [password, setPassword] = useState(null);
  const [customEntry, setCustomEntry] = useState(false);
  const [fixedClasses, setFixedClasses] = useState("dropdown");
  const [checked, setChecked] = useState([1]);
  const members = useSelector((state) => state.member.members);

  useEffect(() => {
    if (fullScreen) {
      setFixedClasses("dropdown");
    } else {
      setFixedClasses("dropdown show");
    }
  }, [fullScreen]);

  useEffect(() => {
    dispatch(getPaginatedMembers(8));
    return () => {
      dispatch(resetMembers({}));
      dispatch(setMembersSnapshot(null));
    };
  }, [dispatch]);

  // end
  const handleClick = () => {
    // props.handleFixedClick();
    if (fixedClasses === "dropdown") {
      setFixedClasses("dropdown show");
    } else {
      setFixedClasses("dropdown");
    }
  };

  const handleUsername = (value) => {
    setUserName(value);
    let email = value;
    if (domain !== NOT_LISTED && value) {
      email = value + domain;
    }
    setEmail(email);
  };

  const handleDomainSwitch = (event) => {
    setDomain(event.target.value);
    rules.email.touched = false;
    if (event.target.value === NOT_LISTED) {
      setCustomEntry(true);
    }
    setUserName(null);
    setEmail(null);
  };

  const handleToggle = (value) => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];
    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }
    setChecked(newChecked);
  };

  // const reset = () => {
  //   setCustomEntry(false);
  //   setDomain(emailDomains[0]);
  //   setEmail(null);
  //   setUserName(null);
  //   setPassword(null);
  //   resetRules();
  // };

  const handleSignUp = () => {
    const displayName = email.split("@")[0];
    dispatch(setNextNavRoute(MY_HOLLERS_ROUTE));
    dispatch(registerUIStartLoading());
    setTimeout(() => {
      dispatch(signUp({ email, password, displayName }, history));
      Event(
        SIGN_UP_CATEGORY,
        SUBMIT_SIGN_UP_SIDE_ACTION,
        SUBMIT_SIGN_UP_SIDE_LABEL
      );
      pushDataLayer({
        dataLayer: {
          event: SUBMIT_SIGN_UP_CONVERSION,
        },
      });
    }, 500);
  };

  const renderTextFieldB = (
    name,
    value,
    placeholder,
    icon,
    classes,
    setField,
    secure,
    multiline = false,
    options
  ) => {
    const rule = rules[name];
    let validateValue = value;
    if (name === "email" && domain !== NOT_LISTED && validateValue) {
      validateValue += domain;
    }
    const invalid = rule.touched && !checkValidity(validateValue, rule);
    const errorMsg = rule.errorMsg;
    return (
      <CustomInput
        formControlProps={{
          fullWidth: true,
          className: classesObj.formControlCustom,
        }}
        labelProps={{
          classes: {
            root: classesObj.labelRootCustom,
          },
        }}
        inputProps={{
          startAdornment: (
            <InputAdornment position="start" className={classes.inputAdornment}>
              <Icon className={classes.inputAdornmentIcon}>{icon}</Icon>
            </InputAdornment>
          ),
          endAdornment: !isEmpty(options) ? (
            <InputAdornment position="end" className={classes.inputAdornment}>
              <FormControl
                fullWidth={true}
                className={classes.selectFormControl}
              >
                <Select
                  disableUnderline={true}
                  MenuProps={{
                    className: classes.selectMenu,
                  }}
                  classes={{
                    select: classes.select,
                  }}
                  value={domain}
                  onChange={handleDomainSwitch}
                  inputProps={{
                    name: "domainSelect",
                    id: "domain-select",
                  }}
                >
                  {options.map((option) => (
                    <MenuItem
                      key={option}
                      classes={{
                        root: classes.selectMenuItem,
                        selected: classes.selectMenuItemSelected,
                      }}
                      value={option}
                    >
                      {option}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </InputAdornment>
          ) : null,
          placeholder: placeholder,
          onChange: (event) =>
            handleTextChange(name, event.target.value, setField),
          type: secure ? "password" : "text",
          value: value || "",
          multiline,
          rows: 2,
        }}
        success={rule.touched && !invalid}
        error={invalid}
        labelText={invalid && errorMsg}
      />
    );
  };

  const renderSignup = () => {
    return (
      <React.Fragment>
        <li
          className="header-title"
          style={{
            marginTop: 16,
            fontSize: 20,
          }}
        >
          Holler with us 🤓
          {/*<div*/}
          {/*  style={{*/}
          {/*    display: "flex",*/}
          {/*    justifyContent: "center"*/}
          {/*  }}*/}
          {/*>*/}
          {/*  <div*/}
          {/*    style={{*/}
          {/*      alignItems: "center",*/}
          {/*      marginRight: 8,*/}
          {/*      display: "flex"*/}
          {/*    }}*/}
          {/*  >*/}
          {/*    <ChatBubbleOutlineIcon />*/}
          {/*  </div>*/}
          {/*  <div>Holler with us 🤓</div>*/}
          {/*</div>*/}
        </li>
        <li className={classesObj.gridListContainer}>
          {renderTextFieldB(
            "email",
            username,
            customEntry ? `Enter Email Address` : `Username`,
            "email",
            classesObj,
            handleUsername,
            false,
            false,
            customEntry ? [] : emailDomains
          )}
        </li>
        <li className={classesObj.gridListContainer}>
          {renderTextFieldB(
            "password",
            password,
            `Password`,
            "lock_outline",
            classesObj,
            setPassword,
            true,
            false,
            []
          )}
        </li>
      </React.Fragment>
    );
  };

  const classesObj = useStyles();
  return (
    <div
      className={
        "fixed-plugin" +
        (props.rtlActive ? " fixed-plugin-rtl" : "") +
        ` ${classesObj.fixed} `
      }
    >
      <div id="fixedPluginClasses" className={fixedClasses}>
        <div className={classesObj.click} onClick={handleClick}>
          <i className="fas fa-comments fa-2x" />
        </div>
        <ul
          className={
            fullScreen
              ? `dropdown-menu ${classesObj.dropDownMobile}`
              : "dropdown-menu"
          }
        >
          {renderSignup()}

          <li
            className={`button-container`}
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <div>
              {renderCheckBoxField(
                checked,
                "terms of use",
                "/terms",
                handleToggle,
                classesObj
              )}
            </div>
          </li>

          <li
            className={`button-container ${classesObj.buttonContainerCustom}`}
          >
            <div>
              {registerIsLoading ? (
                <CircularProgress
                  classes={{
                    root: classesObj.loading,
                  }}
                  color="secondary"
                  size={30}
                />
              ) : (
                <Button
                  color="rose"
                  rel="noopener noreferrer"
                  disabled={!enableRegisterBtn(email, password, checked)}
                  onClick={handleSignUp}
                  round
                  className={classesObj.button}
                  // fullWidth
                >
                  Join the community!
                </Button>
              )}
            </div>
          </li>

          {!isEmpty(members) && (
            <li className={classesObj.gridListContainer}>
              <h5
                style={{
                  textAlign: "left",
                  fontWeight: 500,
                  margin: 0,
                  marginBottom: 16,
                }}
              >
                Our Newest Members 🥳🎉
              </h5>
              <div>
                {Object.keys(members).map((memberId) => {
                  const member = members[memberId];
                  const {
                    displayName,
                    photoURL,
                    createdAt,
                    socialAvatar,
                  } = member;
                  const imageURL = profileImg(
                    {
                      photoURL: photoURL,
                      socialAvatar,
                      displayName,
                    },
                    80,
                    FB_LARGE
                  );
                  return (
                    <Tooltip
                      key={memberId}
                      id="tooltip-feature"
                      title={`${displayName} (Joined ${moment(
                        createdAt
                      ).fromNow()})`}
                      placement="top"
                      classes={{ tooltip: classesObj.tooltip }}
                    >
                      <img
                        src={imageURL}
                        className={classNames(
                          classesObj.img,
                          classesObj.imgRaised,
                          classesObj.imgRounded
                        )}
                        alt="..."
                      />
                    </Tooltip>
                  );
                })}
              </div>
            </li>
          )}
        </ul>
      </div>
    </div>
  );
}

AuthFixedPlugin.propTypes = {
  bgImage: PropTypes.string,
  handleFixedClick: PropTypes.func,
  miniActive: PropTypes.bool,
  fixedClasses: PropTypes.string,
  bgColor: PropTypes.oneOf(["white", "black", "blue"]),
  color: PropTypes.oneOf([
    "white",
    "red",
    "orange",
    "green",
    "blue",
    "purple",
    "rose",
  ]),
  handleBgColorClick: PropTypes.func,
  handleColorClick: PropTypes.func,
  handleImageClick: PropTypes.func,
  sidebarMinimize: PropTypes.func,
  rtlActive: PropTypes.bool,
};
