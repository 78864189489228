import moment from "moment";

const emojis = [
  "🤡",
  "🥳",
  "🤓",
  "🧐",
  "👻",
  "🤠",
  "🥳",
  "😎",
  "😼",
  "🕵🏻",
  "🕵🏻",
  "🐵",
  "🙈",
  "🙊"
];
export const NOT_LISTED = "Not Listed Here";
export const emailDomains = [
  "@gmail.com",
  "@outlook.com",
  "@hotmail.com",
  "@yahoo.com",
  "@mail.com",
  "@aol.com",
  "@icloud.com",
  NOT_LISTED
];

const placeHolder = {
  placeHolder1: {
    title: "Spot something interesting?",
    desc: "Eg: Comprehensive car cleaning services found here!",
    category: "services",
    createdAt: moment().toISOString()
  },
  placeHolder2: {
    title: "Spot something interesting?",
    desc: "Eg: 1 for 1 deal spotted over here right now!",
    category: "shopping",
    createdAt: moment().toISOString()
  },
  placeHolder3: {
    title: "Spot something interesting?",
    desc: "Eg: Live performance going on here!",
    category: "events",
    createdAt: moment().toISOString()
  },
  placeHolder4: {
    title: "Spot something interesting?",
    desc: "Eg: Really good street food!",
    category: "meals",
    createdAt: moment().toISOString()
  }
};

export { placeHolder, emojis };
