export const RESET_HOLLERS = "RESET_HOLLERS";
export const RESET_LATEST_HOLLERS = "RESET_LATEST_HOLLERS";
export const RESET_FEATURED_HOLLERS = "RESET_FEATURED_HOLLERS";
export const GET_LATEST_HOLLERS = "GET_LATEST_HOLLERS";
export const GET_FEATURED_HOLLERS = "GET_FEATURED_HOLLERS";
export const GET_HOLLER = "GET_HOLLER";
export const SET_EDIT_HOLLER = "SET_EDIT_HOLLER";
export const SET_CONFIRM_FEATURE_HOLLER = "SET_CONFIRM_FEATURE_HOLLER";
export const HOLLER_RATINGS = "HOLLER_RATINGS";
export const SET_HOLLER_FILTER = "SET_HOLLER_FILTER";
export const HOLLER_LATEST_SNAPSHOT = "HOLLER_LATEST_SNAPSHOT";
export const HOLLER_FEATURED_SNAPSHOT = "HOLLER_FEATURED_SNAPSHOT";
export const HOLLER_MODERATE_SNAPSHOT = "HOLLER_MODERATE_SNAPSHOT";

export const HOLLER_LIVE_MAP_SELECT = "HOLLER_LIVE_MAP_SELECT";
