import { setSnackBarMessage } from "./ui";
import {
  REV_GEO_CODE,
  RESET_ADDRESS,
  USER_SELECT_LOCATION,
  USER_SELECT_PLACE,
  SELECT_MARKER,
  REGION_SWITCH,
} from "../types/placeTypes";
import {
  REVERSE_GEO_PENDING,
  REVERSE_GEO_SUCCESS,
  REVERSE_GEO_ERROR,
} from "../../constants/messages";
import axios from "axios";
import { DEFAULT_ADDRESS } from "../../constants/places";
import materialTheme from "../../constants/theme";

// geocode apis (or google web services) cannot be used with http referer restrictions
// solution: create a new api key for production use google cloud functions as a proxy
// idea: the API key will at the very least not be available on the browser
// note: we cannot use ip address restriction since google hosting using dynamic ips

const { COLORS } = materialTheme;
const { INFO, ERROR, SUCCESS } = COLORS;

export const reverseGeoCode = (lat, lng) => {
  return async (dispatch) => {
    try {
      dispatch(
        setSnackBarMessage({
          message: REVERSE_GEO_PENDING,
          snackColor: INFO,
          autoHideDuration: 3000,
        })
      );
      const response = await axios.get(`/geocode/${lat}/${lng}`);
      const payload = response.data;
      const { address } = payload;
      if (address !== DEFAULT_ADDRESS) {
        dispatch(
          setSnackBarMessage({
            message: REVERSE_GEO_SUCCESS,
            snackColor: SUCCESS,
            autoHideDuration: 3000,
          })
        );
      }
      dispatch(setUserChosenLocation({ latitude: lat, longitude: lng }));
      dispatch(setReverseGeoCode(address));
      dispatch(setUserChosenPlace(null));
    } catch (err) {
      dispatch(
        setSnackBarMessage({
          message: REVERSE_GEO_ERROR,
          snackColor: ERROR,
          autoHideDuration: 3000,
        })
      );
    }
  };
};

export const userSelectPlace = (place) => {
  return async (dispatch) => {
    try {
      if (place) {
        const lat = place.geometry.location.lat();
        const lng = place.geometry.location.lng();
        const address = place.formatted_address;
        dispatch(setUserChosenLocation({ latitude: lat, longitude: lng }));
        dispatch(setReverseGeoCode(address));
        dispatch(setUserChosenPlace(place));
      }
    } catch (error) {
      console.error("error setting user place", error);
    }
  };
};

export const resetAddress = () => {
  return {
    type: RESET_ADDRESS,
  };
};

export const setReverseGeoCode = (address) => {
  return {
    type: REV_GEO_CODE,
    address: address,
  };
};

export const setUserChosenLocation = (location) => {
  return {
    type: USER_SELECT_LOCATION,
    selectedLocation: location,
  };
};

export const setUserChosenPlace = (place) => {
  return {
    type: USER_SELECT_PLACE,
    selectedPlace: place,
  };
};

export const setSelectedMarker = (holler) => {
  return {
    type: SELECT_MARKER,
    selectedMarker: holler,
  };
};

export const setRegionChange = (region) => {
  return {
    type: REGION_SWITCH,
    region: region,
  };
};
