import uuid from "uuid-v4";
import validUrl from "valid-url";
import { storage } from "../../shared/firebase";

export const uploadImage = (blob, refPath) => {
  return new Promise(async (resolve, reject) => {
    try {
      const { type } = blob;
      let metaData = { contentType: type };
      let ext = "jpg";
      if (type === "image/png") {
        ext = "png";
      }
      // const ext = blob.name.split(".").pop(); // Extract image extension
      const filename = `${uuid()}.${ext}`; // Generate unique name
      const bucketRef = storage.ref(`${refPath}/${filename}`);
      await bucketRef.put(blob, metaData);
      const downloadURL = await bucketRef.getDownloadURL();
      resolve({ downloadURL, ref: bucketRef.fullPath });
    } catch (err) {
      console.log("upload err", err);
      reject(null);
    }
  });
};

export const uploadImageString = (imageDataUrl, refPath) => {
  return new Promise(async (resolve, reject) => {
    try {
      let metaData = { contentType: "image/jpeg" };
      const tokens = imageDataUrl.split(";");
      let ext = "jpg";
      if (tokens[0] !== "data:image/jpeg" && tokens[0] !== "data:image/png") {
        reject("Invalid file format");
      }
      // if (tokens[0] === "data:image/jpeg") {
      //   ext = "jpg";
      // }
      if (tokens[0] === "data:image/png") {
        ext = "png";
        metaData = { contentType: "image/png" };
      }
      const filename = `${uuid()}.${ext}`; // Generate unique name
      const bucketRef = storage.ref(`${refPath}/${filename}`);
      await bucketRef.putString(imageDataUrl, "data_url", metaData);
      const downloadURL = await bucketRef.getDownloadURL();
      resolve({ downloadURL, ref: bucketRef.fullPath });
    } catch (err) {
      console.log("upload err", err);
      reject(null);
    }
  });
};

export const deleteImage = path => {
  return new Promise(async (resolve, reject) => {
    try {
      const bucketRef = storage.ref(path);
      await bucketRef.delete();
      resolve(path);
    } catch (err) {
      reject(null);
    }
  });
};

/**
 * @param {HTMLImageElement} image - Image File Object
 * @param {Object} crop - crop Object
 * @param {String} fileName - Name of the returned file in Promise
 */
export const getCroppedImg = (image, crop, fileName, document) => {
  const canvas = document.createElement("canvas");
  const scaleX = image.naturalWidth / image.width;
  const scaleY = image.naturalHeight / image.height;
  canvas.width = crop.width;
  canvas.height = crop.height;
  const ctx = canvas.getContext("2d");

  ctx.drawImage(
    image,
    crop.x * scaleX,
    crop.y * scaleY,
    crop.width * scaleX,
    crop.height * scaleY,
    0,
    0,
    crop.width,
    crop.height
  );

  // As Base64 string
  // const base64Image = canvas.toDataURL('image/jpeg');

  // As a blob
  return new Promise((resolve, reject) => {
    canvas.toBlob(
      blob => {
        if (!blob) {
          reject(new Error("Canvas is empty"));
          return;
        }
        // blob.name = fileName;
        resolve(blob);
      },
      "image/jpeg",
      1
    );
  });
};

export const isValidWebUrl = urlCandidate => {
  return validUrl.isWebUri(urlCandidate);
};
