import moment from "moment";
import isEmpty from "lodash.isempty";
import validUrl from "valid-url";
import { FB_LARGE, formatSocialAvatar, profileImg } from "../../shared/avatar";

export const processSnapshot = (
  hollersCopy,
  change,
  includeExpiry = false,
  range = -1
) => {
  const data = change.doc.data();
  let changed = false;
  const type = change.type;
  const holler = data.d ? data.d : data;
  const { expireAt, blocked, createdAt } = holler;
  const current = new Date().toISOString();
  let expired = false;
  let exceedBoundary = false;
  if (includeExpiry) {
    expired = new Date(expireAt) < new Date(current);
  }
  if (range > 0) {
    const lowerBoundary = moment().subtract(range, "d");
    if (moment(createdAt).isBefore(lowerBoundary)) {
      exceedBoundary = true;
    }
  }
  if (type === "added" && !expired && !blocked && !exceedBoundary) {
    if (!hollersCopy[change.doc.id]) {
      changed = true;
      hollersCopy[change.doc.id] = {
        docId: change.doc.id,
        ...holler,
      };
    }
  }
  if (type === "modified") {
    changed = true;
    if (!expired && !blocked && !exceedBoundary) {
      hollersCopy[change.doc.id] = {
        docId: change.doc.id,
        ...holler,
      };
    } else {
      delete hollersCopy[change.doc.id];
    }
  }
  if (type === "removed") {
    changed = true;
    if (hollersCopy[change.doc.id]) {
      delete hollersCopy[change.doc.id];
    }
  }
  return changed;
};

export const sortHollersByTimestamp = (hollers) => {
  const sortedHollers = {};
  const sorted = Object.entries(hollers).sort((a, b) => {
    const bData = b[1];
    const aData = a[1];
    return new Date(bData.createdAt) - new Date(aData.createdAt);
  });
  sorted.forEach((item) => {
    sortedHollers[item[0]] = item[1];
  });
  return sortedHollers;
};

export const extractHollerShowcaseImage = (holler) => {
  if (!holler) return null;
  const { images, gifs, userImage, displayName } = holler;
  const image = !isEmpty(images) ? images[0] : {};
  const { downloadURL } = image;
  const gif = !isEmpty(gifs) ? gifs[0] : null;
  const photoURL = formatSocialAvatar(userImage, FB_LARGE);
  let backgroundImage = profileImg(
    {
      photoURL: photoURL,
      displayName,
    },
    "160",
    FB_LARGE
  );
  if (downloadURL) {
    backgroundImage = downloadURL;
  } else if (gif) {
    backgroundImage = gif;
  }
  return backgroundImage;
};

export const isSocialAvatarUserImage = (image) => {
  try {
    if (!image) return false;
    if (!validUrl.isWebUri(image)) {
      return false;
    }
    const hostName = new URL(image).toString();
    return !hostName.includes("firebasestorage");
  } catch (e) {
    return false;
  }
};

// export const processLikesSnapshot = (likesCopy, change) => {
//   const data = change.doc.data();
//   let changed = false;
//   const type = change.type;
//   if (type === "added") {
//     if (!likesCopy[change.doc.id]) {
//       changed = true;
//       likesCopy[change.doc.id] = {
//         docId: change.doc.id,
//         ...data
//       };
//     }
//   }
//   if (type === "modified") {
//     changed = true;
//     likesCopy[change.doc.id] = {
//       docId: change.doc.id,
//       ...data
//     };
//   }
//   if (type === "removed") {
//     changed = true;
//     if (likesCopy[change.doc.id]) {
//       delete likesCopy[change.doc.id];
//     }
//   }
//   return changed;
// };
//
// export const processDislikesSnapshot = (dislikesCopy, change) => {
//   const data = change.doc.data();
//   let changed = false;
//   const type = change.type;
//   if (type === "added") {
//     if (!dislikesCopy[change.doc.id]) {
//       changed = true;
//       dislikesCopy[change.doc.id] = {
//         docId: change.doc.id,
//         ...data
//       };
//     }
//   }
//   if (type === "modified") {
//     changed = true;
//     dislikesCopy[change.doc.id] = {
//       docId: change.doc.id,
//       ...data
//     };
//   }
//   if (type === "removed") {
//     changed = true;
//     if (dislikesCopy[change.doc.id]) {
//       delete dislikesCopy[change.doc.id];
//     }
//   }
//   return changed;
// };
