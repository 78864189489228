import {
  AUTH_UI_START_LOADING,
  AUTH_UI_STOP_LOADING,
  REGISTER_UI_START_LOADING,
  REGISTER_UI_STOP_LOADING,
  HOLLER_UI_START_LOADING,
  HOLLER_UI_STOP_LOADING,
  HOLLER_UPDATE_START_LOADING,
  HOLLER_UPDATE_STOP_LOADING,
  COMMENT_UI_START_LOADING,
  COMMENT_UI_STOP_LOADING,
  LOGIN_UI_MODAL_DISPLAY,
  MENU_UI_DRAWER_DISPLAY,
  SET_SNACK_BAR_MESSAGE,
  HOLLER_UI_MODAL_DISPLAY,
  FILTER_UI_MODAL_DISPLAY,
  PLAN_CONFIRMATION_UI_MODAL_DISPLAY,
  TRANSACTIONS_UI_START_LOADING,
  TRANSACTIONS_UI_STOP_LOADING,
  MEMBERS_UI_START_LOADING,
  MEMBERS_UI_STOP_LOADING,
} from "../types/uiTypes";

const initialState = {
  authIsLoading: false,
  registerIsLoading: false,
  hollerIsLoading: false,
  hollerUpdateIsLoading: false,
  commentIsLoading: false,
  display: false,
  menuDrawer: false,
  snackBarMessage: null,
  hollerDisplay: false,
  filterDisplay: false,
  planId: null,
  transactionIsLoading: false,
  membersIsLoading: false,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case AUTH_UI_START_LOADING:
      return {
        ...state,
        authIsLoading: true,
      };
    case AUTH_UI_STOP_LOADING:
      return {
        ...state,
        authIsLoading: false,
      };
    case REGISTER_UI_START_LOADING:
      return {
        ...state,
        registerIsLoading: true,
      };
    case REGISTER_UI_STOP_LOADING:
      return {
        ...state,
        registerIsLoading: false,
      };
    case HOLLER_UI_START_LOADING:
      return {
        ...state,
        hollerIsLoading: true,
      };
    case HOLLER_UI_STOP_LOADING:
      return {
        ...state,
        hollerIsLoading: false,
      };
    case HOLLER_UPDATE_START_LOADING:
      return {
        ...state,
        hollerUpdateIsLoading: true,
      };
    case HOLLER_UPDATE_STOP_LOADING:
      return {
        ...state,
        hollerUpdateIsLoading: false,
      };
    case COMMENT_UI_START_LOADING:
      return {
        ...state,
        commentIsLoading: true,
      };
    case COMMENT_UI_STOP_LOADING:
      return {
        ...state,
        commentIsLoading: false,
      };
    case LOGIN_UI_MODAL_DISPLAY:
      return {
        ...state,
        display: action.display,
      };
    case PLAN_CONFIRMATION_UI_MODAL_DISPLAY:
      return {
        ...state,
        planId: action.planId,
      };
    case MENU_UI_DRAWER_DISPLAY:
      return {
        ...state,
        menuDrawer: action.menuDrawer,
      };
    case SET_SNACK_BAR_MESSAGE:
      return {
        ...state,
        snackBarMessage: action.snackBarMessage,
      };
    case HOLLER_UI_MODAL_DISPLAY:
      return {
        ...state,
        hollerDisplay: action.hollerDisplay,
      };
    case FILTER_UI_MODAL_DISPLAY:
      return {
        ...state,
        filterDisplay: action.filterDisplay,
      };
    case TRANSACTIONS_UI_START_LOADING: {
      return {
        ...state,
        transactionIsLoading: true,
      };
    }
    case TRANSACTIONS_UI_STOP_LOADING: {
      return {
        ...state,
        transactionIsLoading: false,
      };
    }
    case MEMBERS_UI_START_LOADING:
      return {
        ...state,
        membersIsLoading: true,
      };
    case MEMBERS_UI_STOP_LOADING:
      return {
        ...state,
        membersIsLoading: false,
      };
    default:
      return state;
  }
};

export default reducer;
