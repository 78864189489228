import {
  section,
  container,
  cardTitle,
  coloredShadow,
  grayColor,
  dangerColor,
  mlAuto,
} from "assets/jss/material-kit-pro-react.js";

import tooltipsStyle from "assets/jss/material-kit-pro-react/tooltipsStyle.js";
import imageStyles from "assets/jss/material-kit-pro-react/imagesStyles.js";

const styles = {
  cardTitle,
  container,
  ...tooltipsStyle,
  ...imageStyles,
  section: {
    ...section,
    padding: "70px 0px",
  },
  coloredShadow,
  cardDescription: {
    color: grayColor[0],
    textAlign: "center",
  },
  mlAuto,
  priceContainer: {
    display: "inline-flex",
  },
  price: {
    fontSize: "18px",
    color: grayColor[22],
  },
  priceOld: {
    fontSize: "16px",
    textDecoration: "line-through",
  },
  priceNew: {
    color: dangerColor[0],
  },
  stats: {
    color: grayColor[0],
  },
  textCenter: {
    textAlign: "center",
  },
  authorFooter: {
    padding: 0,
  },
  author: {
    display: "inline-flex",
    alignItems: "center",
    "& a": {
      color: grayColor[1],
    },
  },
  avatar: {
    width: "30px",
    height: "30px",
    overflow: "hidden",
    borderRadius: "50%",
    marginRight: "5px",
    objectFit: "cover",
  },
  statsB: {
    color: grayColor[0],
    display: "flex",
    "& .fab,& .fas,& .far,& .fal,& .material-icons": {
      position: "relative",
      top: "3px",
      marginRight: "3px",
      marginLeft: "3px",
      fontSize: "18px",
      lineHeight: "18px",
    },
    "& svg": {
      position: "relative",
      top: "3px",
      marginRight: "3px",
      marginLeft: "3px",
      width: "18px",
      height: "18px",
    },
  },
};

export default styles;
