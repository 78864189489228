import { SET_PREV_NAV_ROUTE, SET_NEXT_NAV_ROUTE } from "../types/navTypes";

export const setPrevNavRoute = prevRoute => {
  return {
    type: SET_PREV_NAV_ROUTE,
    prevRoute: prevRoute
  };
};

export const setNextNavRoute = nextRoute => {
  return {
    type: SET_NEXT_NAV_ROUTE,
    nextRoute: nextRoute
  };
};
