import { NO_LOCATION } from "../../constants/messages";

export const displayAddress = location => {
  let address = NO_LOCATION;
  if (location) {
    if (location.place) {
      address = location.place.name;
    } else {
      address = location.address;
    }
  }
  return address;
};

export const parsePlaceDetails = result => {
  if (result) {
    const { formatted_address, geometry } = result;
    const { name, opening_hours } = result;
    const { international_phone_number, place_id } = result;
    const { rating, user_ratings_total } = result;
    const { website, photos, reviews } = result;
    const formatPhotos = [];
    const defaultValue = "Not available";
    if (photos) {
      photos.forEach(photo => {
        const { height, html_attributions, width } = photo;
        formatPhotos.push({
          height,
          html_attributions,
          width,
          photo_url: photo.getUrl()
        });
      });
    }

    return {
      address: formatted_address,
      location: {
        longitude: geometry.location.lng(),
        latitude: geometry.location.lat()
      },
      name: name,
      openingHours: opening_hours ? opening_hours.weekday_text : [],
      phoneNumber: international_phone_number || defaultValue,
      placeID: place_id,
      rating: rating || 0,
      userRatingsTotal: user_ratings_total || 0,
      website: website || defaultValue,
      photos: formatPhotos,
      reviews: reviews || []
    };
  }
  return null;
};
