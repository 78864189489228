import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import { createStore, applyMiddleware, compose, combineReducers } from "redux";
import "assets/scss/material-kit-pro-react.scss?v=1.8.0";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import thunk from "redux-thunk";
import axios from "axios";
import App from "./App";
import * as serviceWorker from "./serviceWorker";

import authReducer from "./store/reducers/auth";
import contactReducer from "./store/reducers/contact";
import hollerReducer from "./store/reducers/holler";
import commentReducer from "./store/reducers/comments";
import placesReducer from "./store/reducers/places";
import uiReducer from "./store/reducers/ui";
import navReducer from "./store/reducers/nav";
import notificationsReducer from "./store/reducers/notifications";
import planReducers from "./store/reducers/plans";
import memberReducers from "./store/reducers/members";

// const composeEnhancers =
//   process.env.NODE_ENV === "development"
//     ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
//     : compose;

const composeEnhancers = compose;

axios.defaults.baseURL =
  process.env.NODE_ENV === "development"
    ? "http://localhost:5001/holler-away-rn-dev/us-central1/api"
    : "https://us-central1-holler-away-rn.cloudfunctions.net/api";

const rootReducer = combineReducers({
  auth: authReducer,
  contact: contactReducer,
  places: placesReducer,
  holler: hollerReducer,
  comment: commentReducer,
  ui: uiReducer,
  nav: navReducer,
  notifications: notificationsReducer,
  plan: planReducers,
  member: memberReducers,
});

const store = createStore(
  rootReducer,
  composeEnhancers(applyMiddleware(thunk))
);

const app = (
  <Provider store={store}>
    <BrowserRouter>
      <App />
      <ToastContainer
        position="bottom-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnVisibilityChange
        draggable
        pauseOnHover
      />
    </BrowserRouter>
  </Provider>
);

ReactDOM.render(app, document.getElementById("root"));
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
