import React from "react";
import { Route, Redirect } from "react-router-dom";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { getIdToken } from "../../store/actions/auth";

// Check for localStorage token. Otherwise we always get redirect immediately
// on hard page reload before the redux action call finishes to fetch the authUser
const token = getIdToken();

const AuthRoute = ({ component: Component, authUser, ...rest }) => (
  <Route
    {...rest}
    render={props =>
      !authUser && !token ? <Redirect to="/" /> : <Component {...props} />
    }
  />
);

const mapStateToProps = state => ({
  authUser: state.auth.authUser
});

AuthRoute.propTypes = {
  user: PropTypes.object
};

export default connect(mapStateToProps)(AuthRoute);
