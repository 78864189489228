import axios from "axios";
import { store, auth, EmailAuthProvider } from "../../shared/firebase";
import {
  SET_PROFILE,
  RESET_PASSWORD_RESULT,
  PENDING_RESET_PASSWORD_RESULT,
  VERIFY_EMAIL_RESULT,
  PENDING_VERIFY_EMAIL_RESULT,
  SET_AUTHENTICATED_USER,
} from "../types/authTypes";
import {
  authUIStartLoading,
  authUIStopLoading,
  registerUIStartLoading,
  registerUIStopLoading,
  setSnackBarMessage,
} from "./ui";
import materialTheme from "../../constants/theme";
import {
  AUTH_PENDING,
  AUTH_ERROR,
  AUTH_SUCCESS,
  AUTH_SIGN_OUT_PENDING,
  AUTH_SIGN_OUT_ERROR,
  AUTH_SIGN_OUT_SUCCESS,
  REGISTER_SIGN_UP_PENDING,
  REGISTER_SIGN_UP_ERROR,
  PROFILE_UPDATE_PENDING,
  PROFILE_UPDATE_ERROR,
  PROFILE_UPDATE_SUCCESS,
  PROFILE_DELETE_PENDING,
  PROFILE_DELETE_ERROR,
  PROFILE_DELETE_SUCCESS,
  EMAIL_INSTRUCTIONS_PENDING,
  EMAIL_INSTRUCTIONS_ERROR,
  EMAIL_INSTRUCTIONS_SUCCESS,
  EMAIL_RESET_PASSWORD_PENDING,
  EMAIL_RESET_PASSWORD_SUCCESS,
  EMAIL_RESET_PASSWORD_ERROR,
  HERO_WELCOME,
} from "../../constants/messages";
import { createPlan, setPlan } from "./plans";
import { uploadImage, deleteImage } from "../utils/images";
import { checkValidity } from "../../shared/utility";
import { removeNotificationTimestamp } from "./notifications";
import isEmpty from "lodash.isempty";
const { COLORS } = materialTheme;
const { INFO, ERROR, SUCCESS, LABEL } = COLORS;

const AuthToken = "AuthToken";

const profileCollection = store.collection("profiles");

export const sso = (authData, history) => {
  return async (dispatch, getState) => {
    try {
      const { email, password } = authData;
      const methods = await auth.fetchSignInMethodsForEmail(email);
      if (isEmpty(methods)) {
        dispatch(signUp(authData, history));
      } else {
        dispatch(signIn({ email, password }, history));
      }
    } catch (err) {
      console.log("sso err", err);
      dispatch(authUIStopLoading());
      dispatch(registerUIStopLoading());
      if (err && err.message) {
        dispatch(
          setSnackBarMessage({
            message: err.message,
            snackColor: ERROR,
            autoHideDuration: 3000,
          })
        );
        return;
      }
      dispatch(
        setSnackBarMessage({
          message: REGISTER_SIGN_UP_ERROR,
          snackColor: ERROR,
          autoHideDuration: 3000,
        })
      );
    }
  };
};

export const signUp = (authData, history) => {
  return async (dispatch, getState) => {
    try {
      dispatch(authUIStartLoading()); // disable auth related ui functions
      dispatch(registerUIStartLoading()); // we need this to disable onAuthStateChanged flow
      dispatch(
        setSnackBarMessage({
          message: REGISTER_SIGN_UP_PENDING,
          snackColor: INFO,
          autoHideDuration: 3000,
        })
      );
      const nextRoute = getState().nav.nextRoute;
      const { email, password, displayName } = authData;
      const credential = await auth.createUserWithEmailAndPassword(
        email,
        password
      );
      const user = credential.user;
      await user.updateProfile({
        displayName,
      });
      await user.sendEmailVerification();
      const token = await user.getIdToken();
      setIdToken(token);
      dispatch(createPlan());
      const currentUser = auth.currentUser;
      const userDoc = {
        userId: currentUser.uid,
        // email: currentUser.email, // email is private info, and should not be in public user profile
        displayName: currentUser.displayName,
        photoURL: currentUser.photoURL,
        photoRef: null,
        createdAt: new Date().toISOString(),
        fcmToken: null,
        type: "user",
        // new fields 26th May, 2020
        followerCount: 0,
        friendsCount: 0,
        followers: {},
        following: {},
        friends: {},
        hidden: false, // direct query
        settings: {
          allowChat: true,
          notifyLike: true,
          notifyHoller: true,
          notifyChat: true,
          notifyFollow: true,
          notifyFriendRequest: true,
          notifyFriendAccept: true,
        },
      };
      await profileCollection.doc(currentUser.uid).set(userDoc);
      const profileDoc = await profileCollection.doc(currentUser.uid).get();
      if (profileDoc.exists) {
        const userData = { session: currentUser, profile: profileDoc.data() };
        dispatch(setAuthUser(userData));
        dispatch(
          setSnackBarMessage({
            message: HERO_WELCOME,
            snackColor: LABEL,
            autoHideDuration: 6000,
          })
        );
        if (nextRoute) {
          history.replace(nextRoute);
        }
      }
      dispatch(authUIStopLoading());
      dispatch(registerUIStopLoading());
    } catch (err) {
      dispatch(authUIStopLoading());
      dispatch(registerUIStopLoading());
      if (err && err.message) {
        dispatch(
          setSnackBarMessage({
            message: err.message,
            snackColor: ERROR,
            autoHideDuration: 3000,
          })
        );
        return;
      }
      dispatch(
        setSnackBarMessage({
          message: REGISTER_SIGN_UP_ERROR,
          snackColor: ERROR,
          autoHideDuration: 3000,
        })
      );
    }
  };
};

export const signIn = (authData, history) => {
  return async (dispatch, getState) => {
    try {
      dispatch(authUIStartLoading());
      dispatch(
        setSnackBarMessage({
          message: AUTH_PENDING,
          snackColor: INFO,
          autoHideDuration: 3000,
        })
      );
      const nextRoute = getState().nav.nextRoute;
      const { email, password } = authData;
      const credential = await auth.signInWithEmailAndPassword(email, password);
      const user = credential.user;
      const token = await user.getIdToken();
      setIdToken(token);
      dispatch(createPlan());
      const profileDoc = await profileCollection.doc(user.uid).get();
      dispatch(authUIStopLoading());
      if (profileDoc.exists) {
        const profile = profileDoc.data();
        const userData = { session: user, profile: profile };
        dispatch(setAuthUser(userData));
        dispatch(
          setSnackBarMessage({
            message: AUTH_SUCCESS(user.displayName),
            snackColor: SUCCESS,
            autoHideDuration: 3000,
          })
        );
        if (nextRoute) {
          history.replace(nextRoute);
        }
      } else {
        dispatch(setAuthUser(null));
        dispatch(
          setSnackBarMessage({
            message: AUTH_ERROR,
            snackColor: ERROR,
            autoHideDuration: 3000,
          })
        );
      }
    } catch (err) {
      dispatch(authUIStopLoading());
      if (err && err.message) {
        dispatch(
          setSnackBarMessage({
            message: err.message,
            snackColor: ERROR,
            autoHideDuration: 3000,
          })
        );
        return;
      }
      dispatch(
        setSnackBarMessage({
          message: AUTH_ERROR,
          snackColor: ERROR,
          autoHideDuration: 3000,
        })
      );
    }
  };
};

export const signOut = () => {
  return async (dispatch) => {
    try {
      dispatch(
        setSnackBarMessage({
          message: AUTH_SIGN_OUT_PENDING,
          snackColor: INFO,
          autoHideDuration: 3000,
        })
      );
      dispatch(setPlan(null));
      dispatch(setAuthUser(null));
      dispatch(
        setSnackBarMessage({
          message: AUTH_SIGN_OUT_SUCCESS,
          snackColor: SUCCESS,
          autoHideDuration: 3000,
        })
      );
      removeNotificationTimestamp();
      await auth.signOut();
    } catch (err) {
      dispatch(
        setSnackBarMessage({
          message: AUTH_SIGN_OUT_ERROR,
          snackColor: ERROR,
          autoHideDuration: 3000,
        })
      );
    }
  };
};

export const profileDelete = (password, history) => {
  return async (dispatch, getState) => {
    try {
      const authUser = getState().auth.authUser;
      if (!authUser || !password) return;
      dispatch(
        setSnackBarMessage({
          message: PROFILE_DELETE_PENDING,
          snackColor: INFO,
          autoHideDuration: 3000,
        })
      );

      const { session, profile } = authUser;
      const { uid, email } = session;
      if (profile.photoRef) {
        await deleteImage(profile.photoRef);
      }
      await profileCollection.doc(uid).delete();
      const credential = EmailAuthProvider.credential(email, password);
      await session.reauthenticateWithCredential(credential);
      await session.delete();
      dispatch(setAuthUser(null));
      dispatch(authUIStopLoading());
      dispatch(
        setSnackBarMessage({
          message: PROFILE_DELETE_SUCCESS,
          snackColor: SUCCESS,
          autoHideDuration: 3000,
        })
      );
      history.replace("/");
    } catch (err) {
      dispatch(
        setSnackBarMessage({
          message: PROFILE_DELETE_ERROR,
          snackColor: ERROR,
          autoHideDuration: 3000,
        })
      );
    }
  };
};

export const sendVerifyEmail = (user) => {
  return async (dispatch) => {
    if (!user) return;
    const { session } = user;
    const { email } = session;
    try {
      dispatch(authUIStartLoading());
      dispatch(
        setSnackBarMessage({
          message: EMAIL_INSTRUCTIONS_PENDING,
          snackColor: INFO,
          autoHideDuration: 3000,
        })
      );
      await session.sendEmailVerification();
      dispatch(authUIStopLoading());
      dispatch(
        setSnackBarMessage({
          message: EMAIL_INSTRUCTIONS_SUCCESS(email),
          snackColor: SUCCESS,
          autoHideDuration: 3000,
        })
      );
    } catch (error) {
      dispatch(authUIStopLoading());
      dispatch(
        setSnackBarMessage({
          message: EMAIL_INSTRUCTIONS_ERROR(email),
          snackColor: ERROR,
          autoHideDuration: 3000,
        })
      );
    }
  };
};

export const profileImageUpload = (imageBlob) => {
  return async (dispatch, getState) => {
    try {
      const authUser = getState().auth.authUser;
      dispatch(authUIStartLoading());
      dispatch(
        setSnackBarMessage({
          message: PROFILE_UPDATE_PENDING,
          snackColor: INFO,
          autoHideDuration: 3000,
        })
      );
      const { session, profile } = authUser;
      const { uid } = session;
      let uploadURL = {};
      if (imageBlob) {
        uploadURL = await uploadImage(imageBlob, "profiles/images");
        if (profile.photoRef) {
          try {
            await deleteImage(profile.photoRef);
          } catch (err) {
            console.error("failed to delete image", profile.photoRef);
          }
        }
        const { downloadURL, ref } = uploadURL;
        const updateData = {
          photoURL: downloadURL,
          photoRef: ref,
        };
        // Note: order is important, when you call session.updateProfile, there will be a call
        // to refresh the user object including the profile. If you do profile collections update
        // after session.updateProfile, the updated user object will not contain profile changes
        await profileCollection.doc(uid).update(updateData);
        await session.updateProfile({
          photoURL: updateData.photoURL,
        });
        const profileDoc = await profileCollection.doc(session.uid).get();
        const userData = { session, profile: profileDoc.data() };
        dispatch(setAuthUser(userData)); // refresh the userData profile
      }

      dispatch(authUIStopLoading());
      dispatch(
        setSnackBarMessage({
          message: PROFILE_UPDATE_SUCCESS,
          snackColor: SUCCESS,
          autoHideDuration: 3000,
        })
      );
    } catch (err) {
      dispatch(authUIStopLoading());
      console.error("profile update error", err);
      dispatch(
        setSnackBarMessage({
          message: PROFILE_UPDATE_ERROR,
          snackColor: ERROR,
          autoHideDuration: 3000,
        })
      );
    }
  };
};

export const profileUpdate = (profileData) => {
  return async (dispatch, getState) => {
    try {
      const authUser = getState().auth.authUser;
      dispatch(authUIStartLoading());
      dispatch(
        setSnackBarMessage({
          message: PROFILE_UPDATE_PENDING,
          snackColor: INFO,
          autoHideDuration: 3000,
        })
      );
      const { session } = authUser;
      const { displayName } = profileData;
      const { uid } = session;
      let updateData = { displayName };
      // Note: order is important, when you call session.updateProfile, there will be a call
      // to refresh the user object including the profile. If you do profile collections update
      // after session.updateProfile, the updated user object will not contain profile changes
      await profileCollection.doc(uid).update(updateData);
      await session.updateProfile({
        displayName: updateData.displayName,
      });
      const profileDoc = await profileCollection.doc(session.uid).get();
      const userData = { session, profile: profileDoc.data() };
      dispatch(setAuthUser(userData)); // refresh the userData profile
      dispatch(authUIStopLoading());
      dispatch(
        setSnackBarMessage({
          message: PROFILE_UPDATE_SUCCESS,
          snackColor: SUCCESS,
          autoHideDuration: 3000,
        })
      );
    } catch (err) {
      dispatch(authUIStopLoading());
      console.error("profile update error", err);
      dispatch(
        setSnackBarMessage({
          message: PROFILE_UPDATE_ERROR,
          snackColor: ERROR,
          autoHideDuration: 3000,
        })
      );
    }
  };
};

export const sendResetInstructions = (email) => {
  return async (dispatch) => {
    try {
      const emailRules = {
        required: true,
        isEmail: true,
      };
      dispatch(authUIStartLoading());
      if (!checkValidity(email, emailRules)) {
        dispatch(authUIStopLoading());
        dispatch(
          setSnackBarMessage({
            message: EMAIL_RESET_PASSWORD_ERROR(email),
            snackColor: ERROR,
            autoHideDuration: 3000,
          })
        );
        return;
      }
      dispatch(
        setSnackBarMessage({
          message: EMAIL_RESET_PASSWORD_PENDING,
          snackColor: INFO,
          autoHideDuration: 3000,
        })
      );
      await auth.sendPasswordResetEmail(email);
      dispatch(
        setSnackBarMessage({
          message: EMAIL_RESET_PASSWORD_SUCCESS(email),
          snackColor: SUCCESS,
          autoHideDuration: 3000,
        })
      );
      dispatch(authUIStopLoading());
    } catch (err) {
      dispatch(authUIStopLoading());
      dispatch(
        setSnackBarMessage({
          message: EMAIL_RESET_PASSWORD_ERROR(email),
          snackColor: ERROR,
          autoHideDuration: 3000,
        })
      );
    }
  };
};

export const resetPassword = (passwordNew, oobCode) => {
  return async (dispatch) => {
    try {
      dispatch(setPendingPasswordResult(true));
      await auth.verifyPasswordResetCode(oobCode);
      await auth.confirmPasswordReset(oobCode, passwordNew);
      dispatch(setPendingPasswordResult(false));
      dispatch(
        setPasswordResult({
          status: true,
          message:
            "Password reset successfully. Please login with your new password.",
        })
      );
    } catch (err) {
      console.error("error reset password", JSON.stringify(err));
      dispatch(setPendingPasswordResult(false));
      dispatch(
        setPasswordResult({
          status: false,
          message: err.message,
        })
      );
    }
  };
};

export const verifyEmail = (oobCode) => {
  return async (dispatch) => {
    try {
      dispatch(setPendingVerifyEmailResult(true));
      await auth.checkActionCode(oobCode);
      await auth.applyActionCode(oobCode);
      dispatch(setPendingVerifyEmailResult(false));
      dispatch(
        setVerifyEmailResult({
          status: true,
          message: "Email verified successfully.",
        })
      );
    } catch (err) {
      console.error("error verifying email", JSON.stringify(err));
      dispatch(setPendingVerifyEmailResult(false));
      dispatch(
        setVerifyEmailResult({
          status: false,
          message: err.message,
        })
      );
    }
  };
};

export const authenticated = (user) => {
  return async (dispatch) => {
    try {
      if (user) {
        const profileDoc = await profileCollection.doc(user.uid).get();
        if (profileDoc.exists) {
          const profile = profileDoc.data();
          const userData = { session: user, profile: profile };
          const token = await user.getIdToken();
          setIdToken(token);
          dispatch(createPlan());
          dispatch(setAuthUser(userData));
          dispatch(
            setSnackBarMessage({
              message: AUTH_SUCCESS(user.displayName),
              snackColor: SUCCESS,
              autoHideDuration: 3000,
            })
          );
        } else {
          dispatch(setAuthUser(null));
        }
      } else {
        dispatch(setAuthUser(null));
      }
    } catch (err) {
      console.error("error setting authenticated user", err);
    }
  };
};

export const getProfile = (userId) => {
  return async (dispatch) => {
    try {
      const profileDoc = await profileCollection.doc(userId).get();
      if (profileDoc.exists) {
        dispatch(setPublicProfile(profileDoc.data()));
      }
    } catch (err) {
      console.error("error fetching user public profile", JSON.stringify(err));
    }
  };
};

export const setPasswordResult = (result) => {
  return {
    type: RESET_PASSWORD_RESULT,
    reset: result,
  };
};

export const setPendingPasswordResult = (result) => {
  return {
    type: PENDING_RESET_PASSWORD_RESULT,
    loading: result,
  };
};

export const setVerifyEmailResult = (result) => {
  return {
    type: VERIFY_EMAIL_RESULT,
    verified: result,
  };
};

export const setPendingVerifyEmailResult = (result) => {
  return {
    type: PENDING_VERIFY_EMAIL_RESULT,
    loading: result,
  };
};

export const setPublicProfile = (profile) => {
  return {
    type: SET_PROFILE,
    profile: profile,
  };
};

export const setAuthUser = (authUser) => {
  if (!authUser) {
    removeIdToken();
  }
  return {
    type: SET_AUTHENTICATED_USER,
    authUser: authUser,
  };
};

export const getIdToken = () => {
  return localStorage.getItem(AuthToken);
};

const setIdToken = (token) => {
  localStorage.setItem(AuthToken, token);
  setAuthorizationHeader(token);
};

const removeIdToken = () => {
  localStorage.removeItem(AuthToken);
  unsetAuthorizationHeader();
};

const setAuthorizationHeader = (token) => {
  // console.log("Auth", `Bearer ${token}`);
  axios.defaults.headers.common.Authorization = `Bearer ${token}`;
};

const unsetAuthorizationHeader = () => {
  delete axios.defaults.headers.common.Authorization;
};
