/*!

=========================================================
* Material Kit PRO React - v1.7.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
// nodejs library to set properties for components
import PropTypes from "prop-types";
// nodejs library that concatenates classes
import classNames from "classnames";
// core components
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import Button from "components/CustomButtons/Button";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";

import pricingStyle from "assets/jss/material-kit-pro-react/views/presentationSections/pricingStyle";
import appStore from "assets/img/appstore.png";
import playStore from "assets/img/playstore.png";
import ScrollAnimation from "react-animate-on-scroll";
import { isMobileDevice } from "../../../../shared/utility";

const style = {
  ...pricingStyle,
  // ...navPillsContentStyle
};

class SectionPricing extends React.Component {
  renderDownloadOptions = () => {
    const userAgent = navigator.userAgent || navigator.vendor || window.opera;
    if (isMobileDevice(navigator)) {
      if (/android/i.test(userAgent)) {
        return (
          <GridContainer justify="center">
            <GridItem md={12} sm={12}>
              <a
                href="https://play.google.com/store/apps/details?id=com.holleraway.holleraway"
                rel="noopener noreferrer"
                target="_blank"
              >
                <img src={playStore} alt="..." width="300" height="101" />
              </a>
            </GridItem>
          </GridContainer>
        );
      }
      if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
        return (
          <GridContainer justify="center">
            <GridItem md={12} sm={12}>
              <a
                href="https://apps.apple.com/us/app/holler-away/id1478522144"
                rel="noopener noreferrer"
                target="_blank"
              >
                <img src={appStore} alt="..." width="300" height="101" />
              </a>
            </GridItem>
          </GridContainer>
        );
      }
    }
    return (
      <GridContainer justify="center">
        <GridItem md={6} sm={6}>
          <a
            href="https://apps.apple.com/us/app/holler-away/id1478522144"
            rel="noopener noreferrer"
            target="_blank"
          >
            <img src={appStore} alt="..." width="300" height="101" />
          </a>
        </GridItem>
        <GridItem md={6} sm={6}>
          <a
            href="https://play.google.com/store/apps/details?id=com.holleraway.holleraway"
            rel="noopener noreferrer"
            target="_blank"
          >
            <img src={playStore} alt="..." width="300" height="101" />
          </a>
        </GridItem>
      </GridContainer>
    );
  };

  render() {
    const { classes } = this.props;
    return (
      <div id="download" className={classes.section}>
        <ScrollAnimation animateIn="fadeInUp">
          <div className={classes.container}>
            <GridContainer>
              <GridItem
                md={8}
                sm={10}
                className={classNames(classes.mlAuto, classes.mrAuto)}
              >
                <h2 className={classes.title}>Download Holler Away Now!</h2>
                {this.renderDownloadOptions()}
              </GridItem>
              <div className={classes.socialLine}>
                <div className={classes.container}>
                  <GridContainer>
                    <GridItem md={12}>
                      <h4 className={classes.title}>
                        Connect with us on social media!
                      </h4>
                      <Button
                        color="twitter"
                        rel="noopener noreferrer"
                        target="_blank"
                        href="https://twitter.com/HollerAway"
                        round
                      >
                        <i
                          className={"fab fa-twitter " + classes.marginRight}
                        />
                        Twitter
                      </Button>
                      <Button
                        color="facebook"
                        rel="noopener noreferrer"
                        target="_blank"
                        href="https://www.facebook.com/hollerawayapp"
                        round
                      >
                        <i
                          className={"fab fa-facebook " + classes.marginRight}
                        />
                        Facebook
                      </Button>
                      <Button
                        color="linkedin"
                        rel="noopener noreferrer"
                        target="_blank"
                        href="https://www.linkedin.com/company/holleraway"
                        round
                      >
                        <i
                          className={
                            "fab fa-linkedin-in " + classes.marginRight
                          }
                        />
                        Linkedin
                      </Button>
                    </GridItem>
                  </GridContainer>
                </div>
              </div>
            </GridContainer>
          </div>
        </ScrollAnimation>
      </div>
    );
  }
}

SectionPricing.propTypes = {
  classes: PropTypes.object,
};

export default withStyles(style)(SectionPricing);
