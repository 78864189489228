/* eslint-disable */
import React from "react";
// nodejs library to set properties for components
import PropTypes from "prop-types";
// react components for routing our app without refresh
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
// @material-ui/core components
import { makeStyles, useTheme } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";

// @material-ui/icons
import Face from "@material-ui/icons/Face";
import Room from "@material-ui/icons/Room";
import LocalOfferIcon from "@material-ui/icons/LocalOffer";
// import BubbleChart from "@material-ui/icons/BubbleChart";
import AccountCircle from "@material-ui/icons/AccountCircle";
import CloudDownload from "@material-ui/icons/CloudDownloadOutlined";
import InfoIcon from "@material-ui/icons/Info";

// core components
import Button from "components/CustomButtons/Button";
import CustomDropdown from "components/CustomDropdown/CustomDropdown";

import styles from "assets/jss/material-kit-pro-react/views/componentsSections/navbarsStyle";
import { isMobileDevice } from "../../shared/utility";
import { signOut } from "../../store/actions/auth";
import { setLoginModal, setMenuDrawer } from "../../store/actions/ui";
import { setNextNavRoute } from "../../store/actions/nav";
import { profileImg } from "../../shared/avatar";
import {
  PROFILE_ROUTE,
  MY_HOLLERS_ROUTE,
  PRICING_ROUTE,
} from "../../constants/routes";

import useMediaQuery from "@material-ui/core/useMediaQuery";
import { Event } from "../Tracking";
import {
  HEADER_SIGN_UP_ACTION,
  HEADER_SIGN_UP_LABEL,
  SIGN_UP_CATEGORY,
} from "../../constants/ga";

import { LEARN_MORE_ROUTE, MAP_ROUTE } from "../../constants/routes";

const useStyles = makeStyles(styles);

const PROFILE = "Profile";
const SIGN_OUT = "Sign Out";

export default function HeaderLinks(props) {
  const history = useHistory();
  const authUser = useSelector((state) => state.auth.authUser);
  const easeInOutQuad = (t, b, c, d) => {
    t /= d / 2;
    if (t < 1) return (c / 2) * t * t + b;
    t--;
    return (-c / 2) * (t * (t - 2) - 1) + b;
  };

  const smoothScroll = (e, target) => {
    if (window.location.pathname === "/sections") {
      const isMobile = navigator.userAgent.match(
        /(iPad)|(iPhone)|(iPod)|(android)|(webOS)/i
      );
      if (isMobile) {
        // if we are on mobile device the scroll into view will be managed by the browser
      } else {
        e.preventDefault();
        const targetScroll = document.getElementById(target);
        scrollGo(document.documentElement, targetScroll.offsetTop, 1250);
      }
    }
  };
  const scrollGo = (element, to, duration) => {
    let start = element.scrollTop,
      change = to - start,
      currentTime = 0,
      increment = 20;

    const animateScroll = function () {
      currentTime += increment;
      const val = easeInOutQuad(currentTime, start, change, duration);
      element.scrollTop = val;
      if (currentTime < duration) {
        setTimeout(animateScroll, increment);
      }
    };
    animateScroll();
  };

  const { samePage } = props;
  const target = isMobileDevice(navigator) ? "_self" : "_blank";
  const classes = useStyles();
  const theme = useTheme();
  const dispatch = useDispatch();
  const menuDisplay = useMediaQuery(theme.breakpoints.down("sm"));
  const { session, profile } = authUser || {};

  const handleCustomDropDownClick = (item) => {
    if (item === PROFILE) {
      history.push(PROFILE_ROUTE);
    }
    if (item === SIGN_OUT) {
      dispatch(signOut());
    }
  };

  return (
    <List className={classes.list + " " + classes.mlAuto}>
      <ListItem className={classes.listItem}>
        <Button
          href={MAP_ROUTE}
          target={target}
          rel="noopener noreferrer"
          className={classes.navLink}
          color="transparent"
        >
          <Room className={classes.icons} /> Real Time Map
        </Button>
      </ListItem>
      {/*<ListItem className={classes.listItem}>*/}
      {/*  <Button*/}
      {/*    href="/hollers"*/}
      {/*    target={target}*/}
      {/*    rel="noopener noreferrer"*/}
      {/*    className={classes.navLink}*/}
      {/*    color="transparent"*/}
      {/*  >*/}
      {/*    <BubbleChart className={classes.icons} /> Latest Hollers*/}
      {/*  </Button>*/}
      {/*</ListItem>*/}
      <ListItem className={classes.listItem}>
        <Button
          href={LEARN_MORE_ROUTE}
          target={target}
          rel="noopener noreferrer"
          className={classes.navLink}
          color="transparent"
        >
          <InfoIcon className={classes.icons} /> Learn More
        </Button>
      </ListItem>
      <ListItem className={classes.listItem}>
        <Button
          href={PRICING_ROUTE}
          target={target}
          rel="noopener noreferrer"
          className={classes.navLink}
          color="transparent"
        >
          <LocalOfferIcon className={classes.icons} /> Pricing
        </Button>
      </ListItem>
      <ListItem className={classes.listItem}>
        {authUser ? (
          <CustomDropdown
            left
            caret={false}
            hoverColor="rose"
            buttonIcon={menuDisplay ? Face : undefined}
            dropdownHeader="Options"
            buttonText={
              menuDisplay ? (
                "My Profile"
              ) : (
                <img
                  src={profileImg({
                    photoURL: profile.photoURL,
                    displayName: profile.displayName,
                    socialAvatar: profile.socialAvatar,
                  })}
                  className={classes.img}
                  style={{
                    objectFit: "cover",
                  }}
                  alt="profile"
                />
              )
            }
            buttonProps={{
              className: menuDisplay
                ? classes.navLink
                : classes.navLink + " " + classes.imageDropdownButton,
              color: "transparent",
              style: {
                marginRight: menuDisplay ? 0 : 16,
              },
            }}
            dropdownList={[PROFILE, SIGN_OUT]}
            onClick={handleCustomDropDownClick}
          />
        ) : (
          <Button
            onClick={() => {
              dispatch(setLoginModal(true));
              dispatch(setMenuDrawer(false));
              dispatch(setNextNavRoute(MY_HOLLERS_ROUTE));
              Event(
                SIGN_UP_CATEGORY,
                HEADER_SIGN_UP_ACTION,
                HEADER_SIGN_UP_LABEL
              );
            }}
            className={classes.navLink}
            color="transparent"
          >
            <AccountCircle className={classes.icons} /> Login/Register
          </Button>
        )}
      </ListItem>
      <ListItem className={classes.listItem}>
        <Button
          href={samePage ? "#download" : "/#download"}
          color={window.innerWidth < 960 ? "primary" : "white"}
          className={classes.navButton}
          round
        >
          <CloudDownload className={classes.icons} /> Download Now
        </Button>
      </ListItem>
    </List>
  );
}

HeaderLinks.defaultProps = {
  hoverColor: "primary",
};

HeaderLinks.propTypes = {
  dropdownHoverColor: PropTypes.oneOf([
    "dark",
    "primary",
    "info",
    "success",
    "warning",
    "danger",
    "rose",
  ]),
};
