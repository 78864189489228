export const DEFAULT_ADDRESS = "No Address Available";
export const DEFAULT_TYPE = "Point of interest";

export const formatPlaceType = type => {
  if (!type) return DEFAULT_TYPE;
  const tokens = type.split("_");
  let format = "";
  tokens.forEach((token, index) => {
    if (index === tokens.length) {
      format += token;
    } else {
      format += token + " ";
    }
  });
  return format;
};
