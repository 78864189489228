import {
  RESET_PASSWORD_RESULT,
  PENDING_RESET_PASSWORD_RESULT,
  VERIFY_EMAIL_RESULT,
  PENDING_VERIFY_EMAIL_RESULT,
  SET_PROFILE,
  SET_AUTHENTICATED_USER
} from "../types/authTypes";

const initialState = {
  reset: null,
  verified: null,
  loading: false,
  profile: null,
  authUser: null,
  authenticated: false
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case RESET_PASSWORD_RESULT:
      return {
        ...state,
        reset: action.reset
      };
    case PENDING_RESET_PASSWORD_RESULT:
      return {
        ...state,
        loading: action.loading
      };
    case VERIFY_EMAIL_RESULT:
      return {
        ...state,
        verified: action.verified
      };
    case PENDING_VERIFY_EMAIL_RESULT:
      return {
        ...state,
        loading: action.loading
      };
    case SET_PROFILE:
      return {
        ...state,
        profile: action.profile
      };
    case SET_AUTHENTICATED_USER:
      return {
        ...state,
        authUser: action.authUser
      };
    default:
      return state;
  }
};

export default reducer;
