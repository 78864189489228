import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
// @material-ui/core components
import { withStyles, makeStyles, useTheme } from "@material-ui/core/styles";
import InputAdornment from "@material-ui/core/InputAdornment";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import Link from "@material-ui/core/Link";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import useMediaQuery from "@material-ui/core/useMediaQuery";

import { useDispatch, useSelector } from "react-redux";
import { setLoginModal } from "../../store/actions/ui";

// @material-ui/icons
import Icon from "@material-ui/core/Icon";
import CloseIcon from "@material-ui/icons/Close";
// import Email from "@material-ui/icons/Email";
import Check from "@material-ui/icons/Check";
import CircularProgress from "@material-ui/core/CircularProgress";

import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import CustomInput from "components/CustomInput/CustomInput";
import Button from "components/CustomButtons/Button";
import {
  signIn,
  signUp,
  sendResetInstructions,
} from "../../store/actions/auth";
import { checkValidity } from "../../shared/utility";
import {
  NAME_ERROR,
  PASSWORD_LOGIN_ERROR,
  PASSWORD_ERROR,
  EMAIL_ERROR,
} from "../../constants/messages";

import signupPageStyle from "assets/jss/material-kit-pro-react/views/signupPageStyle";
import logo from "assets/img/hollerawaylogo.png";
import { setNextNavRoute } from "../../store/actions/nav";
import { Event, pushDataLayer } from "../../components/Tracking";
import {
  SUBMIT_SIGN_UP_ACTION,
  SUBMIT_SIGN_UP_LABEL,
  SIGN_UP_CATEGORY,
  SUBMIT_SIGN_UP_CONVERSION,
} from "../../constants/ga";
import { emailDomains, NOT_LISTED } from "../../variables/general";
import isEmpty from "lodash.isempty";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
    // display: "flex",
    // alignItems: "center"
  },
}))(MuiDialogContent);

// const DialogActions = withStyles(theme => ({
//   root: {
//     margin: 0,
//     padding: theme.spacing(1)
//   }
// }))(MuiDialogActions);

const useStyles = makeStyles(signupPageStyle);

const register = "register";
const login = "login";
const forgot = "forgot";
const password = "password";
const passwordLogin = "passwordLogin";

const meta = {
  [register]: {
    title: "Join Us",
    subtitle: "Holler with us. We need you! 🤗",
    invitation: "Already have an account? ",
    altTitle: "Login",
  },
  [login]: {
    title: "Login",
    subtitle: "Please enter your credentials! 🤓",
    invitation: "Don't have an account? ",
    altTitle: "Register",
  },
  [forgot]: {
    title: "Reset Password",
    subtitle: "Enter email to reset password",
    invitation: "Back to ",
    altTitle: "Login",
  },
};

const rules = {
  email: {
    required: true,
    isEmail: true,
    touched: false,
    errorMsg: EMAIL_ERROR,
  },
  name: { required: true, touched: false, errorMsg: NAME_ERROR },
  password: {
    required: true,
    minLength: 6,
    touched: false,
    errorMsg: PASSWORD_ERROR(6),
  },
  passwordLogin: {
    required: true,
    touched: false,
    errorMsg: PASSWORD_LOGIN_ERROR,
  },
};

// const getWindowDimensions = () => {
//   const { innerWidth: width, innerHeight: height } = window;
//   return {
//     width,
//     height
//   };
// };

// const iPhone5FrameOrSmaller = (width, height) => {
//   return height <= 568;
// };

const enableRegisterBtn = (name, email, password, checked) => {
  return (
    checkValidity(name, rules.name) &&
    checkValidity(email, rules.email) &&
    checkValidity(password, rules.password) &&
    checked.indexOf(1) !== -1
  );
};

const enableLoginBtn = (email, password) => {
  return (
    checkValidity(email, rules.email) &&
    checkValidity(password, rules.passwordLogin)
  );
};

const enableResetBtn = (email) => {
  return checkValidity(email, rules.email);
};

const enableSubmit = (accountType, name, email, password, checked) => {
  if (accountType === register) {
    return !enableRegisterBtn(name, email, password, checked);
  } else if (accountType === login) {
    return !enableLoginBtn(email, password);
  } else {
    return !enableResetBtn(email);
  }
};

const handleTextChange = (name, value, setField) => {
  const rule = rules[name];
  rule.touched = true;
  if (name === passwordLogin) {
    // registration password as it is now stricter, hence re-check validation
    rules.password.touched = true;
  }
  setField(value);
};

// const resetRules = () => {
//   Object.keys(rules).forEach(ruleName => {
//     rules[ruleName].touched = false;
//   });
// };

const renderTextField = (
  name,
  value,
  placeholder,
  icon,
  classes,
  setField,
  secure = false
) => {
  const rule = rules[name];
  const invalid = rule.touched && !checkValidity(value, rule);
  return (
    <CustomInput
      formControlProps={{
        fullWidth: true,
      }}
      inputProps={{
        startAdornment: (
          <InputAdornment position="start" className={classes.inputAdornment}>
            <Icon className={classes.inputAdornmentIcon}>{icon}</Icon>
          </InputAdornment>
        ),
        placeholder: placeholder,
        onChange: (event) =>
          handleTextChange(name, event.target.value, setField),
        type: secure ? password : "text",
        value: value || "",
      }}
      success={rule.touched && !invalid}
      error={invalid}
      labelText={invalid && rule.errorMsg}
    />
  );
};

const renderCheckBoxField = (checked, label, link, handleToggle, classes) => {
  return (
    <FormControlLabel
      classes={{
        label: classes.label,
      }}
      control={
        <Checkbox
          tabIndex={-1}
          onClick={() => handleToggle(1)}
          checkedIcon={<Check className={classes.checkedIcon} />}
          icon={<Check className={classes.uncheckedIcon} />}
          classes={{
            checked: classes.checked,
            root: classes.checkRoot,
          }}
          checked={checked.indexOf(1) !== -1}
        />
      }
      label={
        <span>
          I agree to the{" "}
          <a href={link} target="_blank" rel="noopener noreferrer">
            {label}
          </a>
          .
        </span>
      }
    />
  );
};

export default function AuthenticationModal(props) {
  const theme = useTheme();
  const open = useSelector((state) => state.ui.display);
  const authIsLoading = useSelector((state) => state.ui.authIsLoading);
  const authUser = useSelector((state) => state.auth.authUser);
  const classes = useStyles();
  const fullScreen = useMediaQuery(theme.breakpoints.down("xs"));
  const dispatch = useDispatch();
  const history = useHistory();
  // const [windowDimensions, setWindowDimensions] = useState(
  //   getWindowDimensions()
  // );

  // const { width, height } = windowDimensions;
  const [name, setName] = useState(null);
  const [email, setEmail] = useState(null);
  const [username, setUserName] = useState(null);
  const [password, setPassword] = useState(null);
  const [accountType, setAccountType] = useState(register);
  const [checked, setChecked] = useState([1]);
  const [domain, setDomain] = useState(emailDomains[0]);
  const [customEntry, setCustomEntry] = useState(false);

  const handleClose = () => {
    dispatch(setLoginModal(false));
    dispatch(setNextNavRoute(null));
  };

  const handleUsername = (value) => {
    setUserName(value);
    let email = value;
    if (domain !== NOT_LISTED && value) {
      email = value + domain;
    }
    setEmail(email);
  };

  useEffect(() => {
    // function handleResize() {
    //   setWindowDimensions(getWindowDimensions());
    // }
    if (authUser) {
      dispatch(setLoginModal(false));
    }
    // window.addEventListener("resize", handleResize);
    // return () => window.removeEventListener("resize", handleResize);
  }, [authUser, dispatch]);

  const handleSwitchType = () => {
    setAccountType(
      accountType === register || accountType === forgot ? login : register
    );
  };

  const handleSwitchForgotPassword = () => {
    setAccountType(forgot);
  };

  const handleDomainSwitch = (event) => {
    setDomain(event.target.value);
    rules.email.touched = false;
    if (event.target.value === NOT_LISTED) {
      setCustomEntry(true);
    }
    setUserName(null);
    setEmail(null);
  };

  const handleToggle = (value) => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];
    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }
    setChecked(newChecked);
  };

  const handleSubmit = () => {
    if (accountType === login) {
      dispatch(signIn({ email, password }, history));
    } else if (accountType === register) {
      dispatch(signUp({ email, password, displayName: name }, history));
      Event(SIGN_UP_CATEGORY, SUBMIT_SIGN_UP_ACTION, SUBMIT_SIGN_UP_LABEL);
      pushDataLayer({
        dataLayer: {
          event: SUBMIT_SIGN_UP_CONVERSION,
        },
      });
    } else {
      dispatch(sendResetInstructions(email));
    }
  };

  const renderTextFieldB = (
    name,
    value,
    placeholder,
    icon,
    classes,
    setField,
    secure,
    multiline = false,
    options
  ) => {
    const rule = rules[name];
    let validateValue = value;
    if (name === "email" && domain !== NOT_LISTED && validateValue) {
      validateValue += domain;
    }
    const invalid = rule.touched && !checkValidity(validateValue, rule);
    const errorMsg = rule.errorMsg;
    return (
      <CustomInput
        formControlProps={{
          fullWidth: true,
        }}
        inputProps={{
          startAdornment: (
            <InputAdornment position="start" className={classes.inputAdornment}>
              <Icon className={classes.inputAdornmentIcon}>{icon}</Icon>
            </InputAdornment>
          ),
          endAdornment: !isEmpty(options) ? (
            <InputAdornment position="end" className={classes.inputAdornment}>
              <FormControl
                fullWidth={true}
                className={classes.selectFormControl}
              >
                <Select
                  disableUnderline={true}
                  MenuProps={{
                    className: classes.selectMenu,
                  }}
                  classes={{
                    select: classes.select,
                  }}
                  value={domain}
                  onChange={handleDomainSwitch}
                  inputProps={{
                    name: "domainSelect",
                    id: "domain-select",
                  }}
                >
                  {options.map((option) => (
                    <MenuItem
                      key={option}
                      classes={{
                        root: classes.selectMenuItem,
                        selected: classes.selectMenuItemSelected,
                      }}
                      value={option}
                    >
                      {option}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </InputAdornment>
          ) : null,
          placeholder: placeholder,
          onChange: (event) =>
            handleTextChange(name, event.target.value, setField),
          type: secure ? "password" : "text",
          value: value || "",
          multiline,
          rows: 2,
        }}
        success={rule.touched && !invalid}
        error={invalid}
        labelText={invalid && errorMsg}
      />
    );
  };

  const metaInfo = meta[accountType];
  const { title, subtitle, invitation, altTitle } = metaInfo;

  return (
    <div>
      <Dialog
        fullScreen={fullScreen}
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
        PaperProps={{
          style: {
            alignItems: "center",
          },
        }}
      >
        <DialogTitle id="customized-dialog-title" onClose={handleClose}>
          {/*{iPhone5FrameOrSmaller(width, height) ? title : ""}*/}
          {""}
        </DialogTitle>
        <DialogContent
          style={{
            width: "100%",
            minWidth: fullScreen ? 0 : 568,
          }}
        >
          <GridContainer justify="center">
            <GridItem xs={12} sm={12} md={10}>
              <Card
                className={classes.cardSignup}
                style={{
                  backgroundColor: "transparent",
                  boxShadow: "none",
                  padding: "0px 0px",
                }}
              >
                {/*{iPhone5FrameOrSmaller(width, height) ? null : (*/}
                {/*  <h2 className={classes.cardTitle}>{title}</h2>*/}
                {/*)}*/}
                <h3 className={classes.cardTitle}>{title}</h3>
                <CardBody>
                  <GridContainer justify="center">
                    <GridItem xs={12} sm={12} md={12}>
                      <div className={classes.textCenter}>
                        <img
                          src={logo}
                          alt="..."
                          width="36"
                          height="36"
                          style={{
                            borderRadius: 10,
                          }}
                        />
                        <h5>{subtitle}</h5>
                      </div>
                      <form className={classes.form}>
                        {accountType === register &&
                          renderTextField(
                            "name",
                            name,
                            "Display Name",
                            "face",
                            classes,
                            setName
                          )}
                        {renderTextFieldB(
                          "email",
                          username,
                          customEntry ? `Enter Email Address` : `Username`,
                          "email",
                          classes,
                          handleUsername,
                          false,
                          false,
                          customEntry ? [] : emailDomains
                        )}
                        {accountType === forgot
                          ? null
                          : renderTextField(
                              accountType === register
                                ? "password"
                                : "passwordLogin",
                              password,
                              "Password",
                              "lock_outline",
                              classes,
                              setPassword,
                              true
                            )}
                        {accountType === register &&
                          renderCheckBoxField(
                            checked,
                            "terms and conditions",
                            "/terms",
                            handleToggle,
                            classes
                          )}
                        {accountType === login && (
                          <div
                            className={
                              classes.toggleAccount +
                              " " +
                              classes.forgotPassword
                            }
                          >
                            <Link
                              onClick={handleSwitchForgotPassword}
                              className={classes.toggle}
                            >
                              Forgot password?
                            </Link>{" "}
                          </div>
                        )}
                        <div className={classes.textCenter}>
                          {authIsLoading ? (
                            <CircularProgress
                              className={classes.loadingIndicator}
                              color="secondary"
                              size={30}
                            />
                          ) : (
                            <Button
                              round
                              color="primary"
                              disabled={enableSubmit(
                                accountType,
                                name,
                                email,
                                password,
                                checked
                              )}
                              onClick={handleSubmit}
                            >
                              {title}
                            </Button>
                          )}
                        </div>
                        <div className={classes.toggleAccount}>
                          {invitation}
                          <Link
                            onClick={handleSwitchType}
                            className={classes.toggle}
                          >
                            {altTitle}
                          </Link>{" "}
                          Now!
                        </div>
                      </form>
                    </GridItem>
                  </GridContainer>
                </CardBody>
              </Card>
            </GridItem>
          </GridContainer>
        </DialogContent>
      </Dialog>
    </div>
  );
}
