export const SIGN_UP_CATEGORY = "Sign up";
export const EXPLORE_MORE_CATEGORY = "Explore more";

export const GET_STARTED_ACTION = "User clicks get started.";
export const GET_STARTED_LABEL = "Get started";

export const HEADER_SIGN_UP_ACTION = "User clicks register / login on header";
export const HEADER_SIGN_UP_LABEL = "Header Register / Login";

export const SIDE_BANNER_SIGN_UP_ACTION =
  "User clicks side banner shoutout now";
export const SIDE_BANNER_SIGN_UP_LABEL = "Side banner Register / Login";

export const SUBMIT_SIGN_UP_ACTION = "User clicks submit register";
export const SUBMIT_SIGN_UP_LABEL = "User register account";

export const SUBMIT_SIGN_UP_SIDE_ACTION =
  "User clicks submit register from join community";
export const SUBMIT_SIGN_UP_SIDE_LABEL =
  "User register account from join community";

export const SUBMIT_SIGN_UP_CONVERSION = "sign_up_submit_conversion";

export const SIDE_BANNER_SHOUT_OUT_NOW_ACTION =
  "User clicks side banner shoutout now";
export const SIDE_BANNER_SHOUT_OUT_NOW_LABEL = "Side banner shout out now";
