import React, { useEffect, useState } from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// plugin that creates slider
// @material-ui/core components
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Checkbox from "@material-ui/core/Checkbox";
import Tooltip from "@material-ui/core/Tooltip";
import FormControlLabel from "@material-ui/core/FormControlLabel";
// @material-ui icons
import Favorite from "@material-ui/icons/Favorite";
import ChatBubble from "@material-ui/icons/ChatBubble";
import Cached from "@material-ui/icons/Cached";
import Check from "@material-ui/icons/Check";
import isEmpty from "lodash.isempty";
// core components
import Accordion from "components/Accordion/Accordion";
import Badge from "components/Badge/Badge";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import Card from "components/Card/Card";
import CardHeader from "components/Card/CardHeader";
import CardBody from "components/Card/CardBody";
import CardFooter from "components/Card/CardFooter";
import Button from "components/CustomButtons/Button";
import Clearfix from "components/Clearfix/Clearfix";
import VideoPlayer from "components/Video/VideoPlayer";

import styles from "assets/jss/material-kit-pro-react/views/ecommerceSections/productsStyle";
import ScrollAnimation from "react-animate-on-scroll";
import cache from "../../../../cache/cache.json";
import { useDispatch, useSelector } from "react-redux";
import {
  categories,
  categoryForId,
  DEFAULT_CATEGORY,
  DEFAULT_CATEGORY_ALT,
} from "../../../../constants/categories";
import { profileImg } from "../../../../shared/avatar";
// import bg0 from "assets/img/bg0.jpg";
import { isMobileDevice } from "../../../../shared/utility";
import moment from "moment";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { getHollers } from "../../../../store/actions/holler";
import CircularProgress from "@material-ui/core/CircularProgress";
import { types, typesForId } from "../../../../constants/entities";
import {
  extractHollerShowcaseImage,
  isSocialAvatarUserImage,
} from "../../../../store/utils/holler";

const useStyles = makeStyles(styles);

const generateFiltered = () => {
  const filtered = [];
  categories.forEach(({ id }) => {
    filtered.push(id);
  });
  return filtered;
};

const generateFilteredTypes = () => {
  const filtered = [];
  types.forEach(({ id }) => {
    filtered.push(id);
  });
  return filtered;
};

const formatTitle = (title) => {
  if (!title) return "";
  if (title.length > 48) {
    return title.substring(0, 48) + "...";
  }
  return title;
};

const formatDescription = (description) => {
  if (!description) return "";
  if (description.length > 70) {
    return description.substring(0, 70) + "...";
  }
  return description;
};

const formatAuthor = (displayName) => {
  if (!displayName) return "";
  if (displayName.length > 15) {
    return displayName.substring(0, 15) + "...";
  }
  return displayName;
};

const renderHollerCard = (holler, classes) => {
  const { docId, title, address, createdAt, desc, categories } = holler;
  const { displayName, userImage, type } = holler;
  const { likes, commentCount } = holler;
  const { videos } = holler;
  const category = isEmpty(categories) ? DEFAULT_CATEGORY_ALT : categories[0];
  const categoryMeta = categoryForId(category);
  const avatar = profileImg({
    photoURL: userImage,
    displayName,
  });
  const backgroundImage = extractHollerShowcaseImage(holler);
  const previewLink = `/preview/${docId}`;
  const liveMapLink = `/map?hollerId=${docId}`;
  const target = isMobileDevice(navigator) ? "_self" : "_blank";
  const badgeMeta = typesForId(type);
  const isSocialAvatar = isSocialAvatarUserImage(userImage);

  return (
    <GridItem xs={12} sm={4} md={4} lg={4} xl={4} key={docId}>
      <Card plain blog>
        <CardHeader image plain>
          <a href={previewLink} target={target} rel="noopener noreferrer">
            {!isEmpty(videos) ? (
              <VideoPlayer
                url={videos[0].downloadURL}
                muted
                loop
                width={"100%"}
                height={235}
                config={{
                  file: {
                    attributes: {
                      style: {
                        objectFit: "cover",
                        width: "100%",
                        height: "100%",
                        borderRadius: 6,
                      },
                    },
                  },
                }}
              />
            ) : (
              <img
                src={backgroundImage}
                alt="..."
                height={235}
                style={{
                  // objectFit: isSocialAvatar ? "scale-down" : "cover",
                  objectFit: "cover",
                }}
              />
            )}
          </a>
          <div
            className={classes.coloredShadow}
            style={{
              backgroundImage: "url(" + backgroundImage + ")",
              opacity: "1",
            }}
          />
        </CardHeader>
        <CardBody plain>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <div
              style={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <Badge color={categoryMeta.colorTag}>{categoryMeta.title}</Badge>
              <Badge color={badgeMeta.colorTag}>{badgeMeta.title}</Badge>
            </div>
            <div
              className={classes.description}
              style={{
                fontSize: 12,
                color: "#3C4858",
              }}
            >
              {moment(createdAt).fromNow()}
            </div>
          </div>
          <h4
            className={classes.cardTitle}
            style={{
              textAlign: "left",
            }}
          >
            <a href={previewLink} target={target} rel="noopener noreferrer">
              {formatTitle(title)}
            </a>
          </h4>
          {desc && (
            <p className={classes.description}>{formatDescription(desc)}</p>
          )}
          <p className={classes.description}>{address}</p>
          <p className={classes.description}>
            <a href={liveMapLink} target={target} rel="noopener noreferrer">
              {" "}
              View On Live Map{" "}
            </a>
          </p>
        </CardBody>
        <CardFooter className={classes.authorFooter}>
          <div className={classes.author}>
            <img
              src={avatar}
              alt={displayName}
              className={classes.imgRaised + " " + classes.avatar}
            />
            <span>{formatAuthor(displayName)}</span>
          </div>
          <div className={classes.stats + " " + classes.mlAuto}>
            <Favorite />
            {likes} ·
            <ChatBubble />
            {commentCount}
          </div>
        </CardFooter>
      </Card>
    </GridItem>
  );
};

export default function SectionProducts() {
  const theme = useTheme();
  const dispatch = useDispatch();
  const fullScreen = useMediaQuery(theme.breakpoints.down("xs"));
  const [filteredCategories, setFilteredCategories] = useState(
    generateFiltered()
  );
  const [filteredTypes, setFilteredTypes] = useState(generateFilteredTypes());
  const [hollers, setHollers] = useState(cache);
  const featuredHollers = useSelector((state) => state.holler.featuredHollers);
  const hollerFeaturedSnapshot = useSelector(
    (state) => state.holler.hollerFeaturedSnapshot
  );

  useEffect(() => {
    dispatch(getHollers(null, 0, DEFAULT_CATEGORY, true, null, null, 9));
  }, [dispatch]);
  useEffect(() => {
    if (!isEmpty(featuredHollers)) {
      setHollers(featuredHollers);
    }
  }, [featuredHollers]);
  const hollerIsLoading = useSelector((state) => state.ui.hollerIsLoading);
  const loadMoreFeatured = () => {
    dispatch(
      getHollers(
        null,
        0,
        DEFAULT_CATEGORY,
        true,
        hollerFeaturedSnapshot,
        null,
        9
      )
    );
  };

  const handleToggle = (value) => {
    const currentIndex = filteredCategories.indexOf(value);
    const newChecked = [...filteredCategories];
    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }
    setFilteredCategories(newChecked);
  };

  const handleToggleType = (value) => {
    const currentIndex = filteredTypes.indexOf(value);
    const newChecked = [...filteredTypes];
    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }
    setFilteredTypes(newChecked);
  };

  const reset = () => {
    setFilteredCategories(generateFiltered());
    setFilteredTypes(generateFilteredTypes());
  };

  const classes = useStyles();

  const filteredHollersIds = Object.keys(hollers).filter((hollerId) => {
    const holler = hollers[hollerId];
    const { type, categories } = holler;
    const category = isEmpty(categories) ? DEFAULT_CATEGORY_ALT : categories[0];
    return (
      filteredCategories.indexOf(category) !== -1 &&
      filteredTypes.indexOf(type) !== -1
    );
  });

  return (
    <div
      className={classes.section}
      style={{
        paddingTop: fullScreen ? 17 : 34,
      }}
    >
      <ScrollAnimation animateIn="fadeInUp">
        <div className={classes.container}>
          <div
            style={{
              textAlign: "center",
              marginBottom: fullScreen ? 0 : 48,
            }}
          >
            <h2>Featured Hollers</h2>
          </div>
          <GridContainer>
            <GridItem md={3} sm={3}>
              <Card plain>
                <CardBody className={classes.cardBodyRefine}>
                  <h4 className={classes.cardTitle + " " + classes.textLeft}>
                    Refine Your Search
                    <Tooltip
                      id="tooltip-top"
                      title="Reset Filter"
                      placement="top"
                      classes={{ tooltip: classes.tooltip }}
                    >
                      <Button
                        link
                        justIcon
                        size="sm"
                        className={
                          classes.pullRight + " " + classes.refineButton
                        }
                        onClick={reset}
                      >
                        <Cached />
                      </Button>
                    </Tooltip>
                    <Clearfix />
                  </h4>
                  <Accordion
                    active={fullScreen ? -1 : [0, 1]}
                    activeColor="rose"
                    collapses={[
                      {
                        title: "Categories",
                        content: (
                          <div className={classes.customExpandPanel}>
                            <div
                              className={
                                classes.checkboxAndRadio +
                                " " +
                                classes.checkboxAndRadioHorizontal
                              }
                            >
                              {categories.map(({ id, title }) => {
                                return (
                                  <FormControlLabel
                                    key={id}
                                    control={
                                      <Checkbox
                                        tabIndex={-1}
                                        onClick={() => handleToggle(id)}
                                        checked={
                                          filteredCategories.indexOf(id) !== -1
                                        }
                                        checkedIcon={
                                          <Check
                                            className={classes.checkedIcon}
                                          />
                                        }
                                        icon={
                                          <Check
                                            className={classes.uncheckedIcon}
                                          />
                                        }
                                        classes={{
                                          checked: classes.checked,
                                          root: classes.checkRoot,
                                        }}
                                      />
                                    }
                                    classes={{ label: classes.label }}
                                    label={title}
                                  />
                                );
                              })}
                            </div>
                          </div>
                        ),
                      },
                      {
                        title: "Types",
                        content: (
                          <div className={classes.customExpandPanel}>
                            <div
                              className={
                                classes.checkboxAndRadio +
                                " " +
                                classes.checkboxAndRadioHorizontal
                              }
                            >
                              {types.map(({ id, title }) => {
                                return (
                                  <FormControlLabel
                                    key={id}
                                    control={
                                      <Checkbox
                                        tabIndex={-1}
                                        onClick={() => handleToggleType(id)}
                                        checked={
                                          filteredTypes.indexOf(id) !== -1
                                        }
                                        checkedIcon={
                                          <Check
                                            className={classes.checkedIcon}
                                          />
                                        }
                                        icon={
                                          <Check
                                            className={classes.uncheckedIcon}
                                          />
                                        }
                                        classes={{
                                          checked: classes.checked,
                                          root: classes.checkRoot,
                                        }}
                                      />
                                    }
                                    classes={{ label: classes.label }}
                                    label={title}
                                  />
                                );
                              })}
                            </div>
                          </div>
                        ),
                      },
                    ]}
                  />
                </CardBody>
              </Card>
            </GridItem>
            <GridItem md={9} sm={9}>
              <GridContainer>
                {filteredHollersIds.map((hollerId) =>
                  renderHollerCard(hollers[hollerId], classes)
                )}
              </GridContainer>

              {isEmpty(filteredHollersIds) && (
                <GridContainer>
                  <GridItem
                    md={9}
                    sm={9}
                    xs={9}
                    className={classNames(classes.mlAuto, classes.mrAuto)}
                  >
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        marginTop: fullScreen ? 0 : 64,
                      }}
                    >
                      <h3>No Matching Results</h3>
                    </div>
                  </GridItem>
                </GridContainer>
              )}

              {!isEmpty(featuredHollers) && !isEmpty(filteredHollersIds) && (
                <GridContainer>
                  <GridItem
                    md={3}
                    sm={9}
                    xs={9}
                    className={classNames(classes.mlAuto, classes.mrAuto)}
                  >
                    {hollerIsLoading ? (
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                        }}
                      >
                        <CircularProgress
                          color="secondary"
                          size={30}
                          classes={{
                            root: classes.loading,
                          }}
                        />
                      </div>
                    ) : (
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                        }}
                      >
                        <Button round color="rose" onClick={loadMoreFeatured}>
                          Load more ...
                        </Button>
                      </div>
                    )}
                  </GridItem>
                </GridContainer>
              )}
            </GridItem>
          </GridContainer>
        </div>
      </ScrollAnimation>
    </div>
  );
}
