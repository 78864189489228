import axios from "axios";
import isEmpty from "lodash.isempty";
import { store, GeoFireStore, GeoPoint } from "../../shared/firebase";
import {
  RESET_HOLLERS,
  RESET_LATEST_HOLLERS,
  RESET_FEATURED_HOLLERS,
  GET_LATEST_HOLLERS,
  GET_FEATURED_HOLLERS,
  GET_HOLLER,
  SET_EDIT_HOLLER,
  HOLLER_RATINGS,
  SET_HOLLER_FILTER,
  HOLLER_LATEST_SNAPSHOT,
  HOLLER_FEATURED_SNAPSHOT,
  HOLLER_LIVE_MAP_SELECT,
  SET_CONFIRM_FEATURE_HOLLER,
} from "../types/hollerTypes";
import {
  hollerUIStopLoading,
  hollerUIStartLoading,
  hollerUpdateStartLoading,
  hollerUpdateStopLoading,
  setSnackBarMessage,
  setHollerModal,
} from "./ui";
import { parsePlaceDetails } from "../utils/places";
import { uploadImage, uploadImageString, deleteImage } from "../utils/images";
import { daysToMilliseconds } from "../utils/time";
import {
  HOLLER_CREATE_PENDING,
  HOLLER_CREATE_SUCCESS,
  HOLLER_CREATE_ERROR,
  HOLLER_UPDATE_PENDING,
  HOLLER_UPDATE_SUCCESS,
  HOLLER_UPDATE_ERROR,
  HOLLER_DELETE_PENDING,
  HOLLER_DELETE_SUCCESS,
  HOLLER_DELETE_ERROR,
  HOLLER_FEATURE_PENDING,
  HOLLER_FEATURE_SUCCESS,
  HOLLER_FEATURE_ERROR,
  HOLLER_EXCEED_LIMIT,
  HOLLER_UPDATE_MEDIA_ERROR,
} from "../../constants/messages";
import { deductHollerLimit, setPlan } from "./plans";
import {
  DEFAULT_CATEGORY,
  DEFAULT_CATEGORY_ALT,
} from "../../constants/categories";
import materialTheme from "../../constants/theme";
import moment from "moment";
import { setRegionChange, setSelectedMarker } from "./places";
import { isExceedLimit } from "../utils/plans";

const likeCollection = store.collection("likes");
const dislikeCollection = store.collection("dislikes");
const hollerCollection = store.collection("hollers");
const hollerGeoCollection = GeoFireStore.collection("hollers");

const { COLORS } = materialTheme;
const { INFO, ERROR, SUCCESS } = COLORS;

export const queryHoller = (
  location,
  radius,
  category,
  featuredOnly,
  lastSnapshot = null,
  pageSize = 6,
  excludeBlock = true
) => {
  let query = null;
  try {
    if (location) {
      query = hollerGeoCollection;
      const { latitude, longitude } = location;
      query = query.near({
        center: new GeoPoint(latitude, longitude),
        radius: radius,
      });
      query =
        category !== DEFAULT_CATEGORY
          ? query.where("categories", "array-contains", category)
          : query;
    } else {
      query = hollerCollection;
      query = query.orderBy("d.createdAt", "desc");
      query = featuredOnly ? query.where("d.featured", "==", true) : query;
      query =
        category !== DEFAULT_CATEGORY
          ? query.where("d.categories", "array-contains", category)
          : query;
      query = excludeBlock ? query.where("d.blocked", "==", false) : query;
      query = lastSnapshot ? query.startAfter(lastSnapshot) : query;
      query = query.limit(pageSize);
    }
    return query;
  } catch (err) {
    // note: stringify to reveal composite index link for complex queries
    console.error("Format Holler query error", JSON.stringify(err));
    return null;
  }
};

export const getHollers = (
  location,
  radius,
  category = DEFAULT_CATEGORY,
  featuredOnly = true,
  snapshot = null,
  range = null,
  pageSize = 6
) => {
  return async (dispatch, getState) => {
    try {
      dispatch(hollerUIStartLoading());
      // const filter = getState().holler.filter;
      const query = queryHoller(
        location,
        radius,
        category,
        featuredOnly,
        snapshot,
        pageSize
      );
      const documents = await query.get();
      const hollers = {};
      const lastSnapshot = !documents.empty
        ? documents.docs[documents.docs.length - 1]
        : null;
      const docs = documents.docs;
      docs.forEach((doc) => {
        const data = doc.data();
        const holler = data.d ? data.d : data;
        const { /*images,*/ blocked /*expireAt*/ } = holler;
        const { createdAt } = holler;
        // const current = new Date().toISOString();
        //  const expired = new Date(expireAt) < new Date(current);
        let includeResult = true;
        // if (blocked || (location && expired) || isEmpty(images)) {
        //   includeResult = false;
        // }
        if (blocked) {
          includeResult = false;
        }
        if (range) {
          const lowerBoundary = moment().subtract(range, "d");
          if (moment(createdAt).isBefore(lowerBoundary)) {
            includeResult = false;
          }
        }
        if (includeResult) {
          hollers[doc.id] = {
            ...holler,
            docId: doc.id,
          };
        }
      });
      dispatch(dispatch(hollerUIStopLoading()));
      if (location) {
        dispatch(resetHollers(hollers));
        return;
      }
      if (featuredOnly) {
        dispatch(setFeaturedHollerSnapshot(lastSnapshot));
        dispatch(setFeaturedHollers(hollers));
      } else {
        dispatch(setLatestHollerSnapshot(lastSnapshot));
        dispatch(setLatestHollers(hollers));
      }
    } catch (err) {
      dispatch(dispatch(hollerUIStopLoading()));
      console.log("get hollers err", err);
      return null;
    }
  };
};

export const getHoller = (hollerId, history) => {
  return async (dispatch) => {
    try {
      const hollerDoc = await hollerCollection.doc(hollerId).get();
      if (hollerDoc.exists) {
        const hollerData = hollerDoc.data().d;
        dispatch(setHoller(hollerData));
        return;
      }
      history.replace("/");
    } catch (error) {
      console.log("get holler error", error);
    }
  };
};

export const createHoller = (
  title,
  desc,
  category,
  location,
  uploadedImages
) => {
  return async (dispatch, getState) => {
    try {
      const authUser = getState().auth.authUser;
      const plan = getState().plan.plan;
      if (isExceedLimit(plan)) {
        dispatch(hollerUpdateStopLoading());
        dispatch(
          setSnackBarMessage({
            message: HOLLER_EXCEED_LIMIT,
            snackColor: ERROR,
            autoHideDuration: 3000,
          })
        );
        return;
      }
      dispatch(hollerUpdateStartLoading());
      dispatch(
        setSnackBarMessage({
          message: HOLLER_CREATE_PENDING,
          snackColor: INFO,
          autoHideDuration: 3000,
        })
      );

      const uploads = [];
      uploadedImages.forEach((path) => {
        if (typeof path === "string") {
          uploads.push(uploadImageString(path, "hollers/images"));
        } else {
          uploads.push(uploadImage(path, "hollers/images"));
        }
      });

      const uploadUrls = await Promise.all(uploads);
      const expireDate = new Date(
        new Date().getTime() + daysToMilliseconds(15)
      );

      const { uid, photoURL, displayName } = authUser.session;
      const { type } = authUser.profile;
      let { place, address, latitude, longitude } = location;
      let geoPoint = null;
      if (place) {
        const placeLat = place.geometry.location.lat();
        const placeLng = place.geometry.location.lng();
        geoPoint = new GeoPoint(placeLat, placeLng);
        address = place.formatted_address;
      } else {
        geoPoint = new GeoPoint(latitude, longitude);
      }

      const newHoller = {
        title: title,
        desc: desc,
        userId: uid,
        displayName: displayName, // i don't want to have to load every user profile for mass display
        userImage: photoURL, // displayName and userImage will only be used for holler list view displays
        type: type,
        category: DEFAULT_CATEGORY_ALT,
        categories: [category],
        followers: {},
        address: address,
        location: geoPoint,
        place: parsePlaceDetails(location.place), // convert to pojo
        createdAt: new Date().toISOString(),
        updatedAt: new Date().toISOString(),
        expireAt: expireDate.toISOString(),
        blocked: false,
        reportProcessed: false, // flag to determine if admin have decided if reported post should be blocked
        likes: 0,
        dislikes: 0,
        commentCount: 0,
        images: uploadUrls,
        videos: [],
      };

      const hollerDocument = await hollerGeoCollection.add(
        newHoller,
        "location"
      );
      dispatch(deductHollerLimit());
      dispatch(hollerUpdateStopLoading());
      dispatch(setHollerModal(false)); // close the modal for create
      const updatedSnapshot = await hollerDocument.get();
      const hollerData = updatedSnapshot.data();
      dispatch(setSelectedMarker({ docId: hollerDocument.id, ...hollerData }));
      dispatch(
        setSnackBarMessage({
          message: HOLLER_CREATE_SUCCESS,
          snackColor: SUCCESS,
          autoHideDuration: 3000,
        })
      );
    } catch (error) {
      console.log("update holler error", error);
      dispatch(hollerUpdateStopLoading());
      dispatch(
        setSnackBarMessage({
          message: HOLLER_CREATE_ERROR,
          snackColor: ERROR,
          autoHideDuration: 3000,
        })
      );
    }
  };
};

export const updateHoller = (
  editHoller,
  title,
  desc,
  category,
  location,
  uploadedImages,
  deletedImages
) => {
  return async (dispatch, getState) => {
    try {
      dispatch(hollerUpdateStartLoading());
      dispatch(
        setSnackBarMessage({
          message: HOLLER_UPDATE_PENDING,
          snackColor: INFO,
          autoHideDuration: 3000,
        })
      );
      const existingHoller = getState().holler.holler;
      const { videos } = editHoller;
      if (
        !isEmpty(videos) &&
        (!isEmpty(uploadedImages) || !isEmpty(deletedImages))
      ) {
        setSnackBarMessage({
          message: HOLLER_UPDATE_MEDIA_ERROR,
          snackColor: ERROR,
          autoHideDuration: 3000,
        });
        return;
      }

      const { docId, images } = editHoller;
      let imagesCopy = Object.assign([], images);
      imagesCopy = imagesCopy.filter(({ ref }) => !deletedImages.includes(ref));

      try {
        const deleted = [];
        deletedImages.forEach((path) => {
          deleted.push(deleteImage(path));
        });
        await Promise.all(deleted);
      } catch (error) {
        console.error("Error deleting holler images", error);
      }

      const uploads = [];
      uploadedImages.forEach((path) => {
        if (typeof path === "string") {
          uploads.push(uploadImageString(path, "hollers/images"));
        } else {
          uploads.push(uploadImage(path, "hollers/images"));
        }
      });
      const uploadUrls = await Promise.all(uploads);
      imagesCopy = [...imagesCopy, ...uploadUrls];

      const geoPoint = new GeoPoint(location.latitude, location.longitude);
      const updateHoller = {
        title,
        desc,
        categories: [category],
        images: imagesCopy,
        location: geoPoint,
        videos: videos || [],
      };
      const hollerDocument = hollerGeoCollection.doc(docId);
      await hollerDocument.update(updateHoller, "location");
      const updatedSnapshot = await hollerDocument.get();
      const holler = updatedSnapshot.data();
      if (existingHoller) {
        dispatch(setHoller({ docId: updatedSnapshot.id, ...holler }));
      }
      dispatch(setEditHoller({ docId: updatedSnapshot.id, ...holler }));
      dispatch(hollerUpdateStopLoading());
      dispatch(
        setSnackBarMessage({
          message: HOLLER_UPDATE_SUCCESS,
          snackColor: SUCCESS,
          autoHideDuration: 3000,
        })
      );
    } catch (error) {
      console.log("update holler error", error);
      dispatch(hollerUpdateStopLoading());
      dispatch(
        setSnackBarMessage({
          message: HOLLER_UPDATE_ERROR,
          snackColor: ERROR,
          autoHideDuration: 3000,
        })
      );
    }
  };
};

export const featureHoller = (selectedHoller) => {
  return async (dispatch, getState) => {
    try {
      const existingHoller = getState().holler.holler;
      dispatch(hollerUpdateStartLoading());
      dispatch(
        setSnackBarMessage({
          message: HOLLER_FEATURE_PENDING,
          snackColor: INFO,
          autoHideDuration: 3000,
        })
      );
      const { docId } = selectedHoller;
      const res = await axios.post("/user/feature", {
        hollerDocId: docId,
      });

      const { plan } = res.data;
      if (existingHoller) {
        const hollerDocument = hollerGeoCollection.doc(docId);
        const updatedSnapshot = await hollerDocument.get();
        const holler = updatedSnapshot.data();
        dispatch(setHoller({ docId: updatedSnapshot.id, ...holler }));
      }
      dispatch(setPlan(plan));
      dispatch(setConfirmFeatureHoller(null));
      dispatch(
        setSnackBarMessage({
          message: HOLLER_FEATURE_SUCCESS,
          snackColor: SUCCESS,
          autoHideDuration: 3000,
        })
      );
      dispatch(hollerUpdateStopLoading());
    } catch (error) {
      dispatch(hollerUpdateStopLoading());
      dispatch(
        setSnackBarMessage({
          message: HOLLER_FEATURE_ERROR,
          snackColor: ERROR,
          autoHideDuration: 3000,
        })
      );
    }
  };
};

export const deleteHoller = (selectedHoller) => {
  return async (dispatch) => {
    try {
      dispatch(hollerUIStartLoading());
      dispatch(
        setSnackBarMessage({
          message: HOLLER_DELETE_PENDING,
          snackColor: INFO,
          autoHideDuration: 3000,
        })
      );
      const { docId, images } = selectedHoller;
      const deletedImages = [];
      images.forEach(({ ref }) => {
        deletedImages.push(ref);
      });

      try {
        const deleted = [];
        deletedImages.forEach((path) => {
          deleted.push(deleteImage(path));
        });
        await Promise.all(deleted);
      } catch (error) {
        console.error("Error deleting holler images", error);
      }

      const hollerDocument = hollerGeoCollection.doc(docId);
      await hollerDocument.delete();
      dispatch(hollerUpdateStopLoading());
      dispatch(
        setSnackBarMessage({
          message: HOLLER_DELETE_SUCCESS,
          snackColor: SUCCESS,
          autoHideDuration: 3000,
        })
      );
    } catch (error) {
      dispatch(hollerUpdateStopLoading());
      dispatch(
        setSnackBarMessage({
          message: HOLLER_DELETE_ERROR,
          snackColor: ERROR,
          autoHideDuration: 3000,
        })
      );
    }
  };
};

export const likeHoller = (hollerId, isDislike) => {
  return async (dispatch, getState) => {
    try {
      dispatch(hollerUIStartLoading());
      const authUser = getState().auth.authUser;
      const { session } = authUser;
      const { displayName, photoURL, uid } = session;
      const hollerDocRef = hollerGeoCollection.doc(hollerId);
      const hollerDocument = await hollerDocRef.get();
      if (!hollerDocument.exists) {
        dispatch(hollerUIStopLoading());
        return;
      }
      const hollerData = hollerDocument.data();

      const query = !isDislike
        ? likeCollection
            .where("sender", "==", uid)
            .where("hollerId", "==", hollerId)
            .limit(1)
        : dislikeCollection
            .where("sender", "==", uid)
            .where("hollerId", "==", hollerId)
            .limit(1);

      const resultDocuments = await query.get();

      if (!isDislike) {
        if (resultDocuments.empty) {
          await likeCollection.add({
            sender: uid,
            receiver: hollerData.userId,
            hollerId: hollerId,
            senderName: displayName,
            senderPhoto: photoURL,
            createdAt: new Date().toISOString(),
          });
        } else {
          const likeDocument = resultDocuments.docs[0];
          await likeCollection.doc(likeDocument.id).delete();
        }
      } else {
        if (resultDocuments.empty) {
          await dislikeCollection.add({
            sender: uid,
            receiver: hollerData.userId,
            hollerId: hollerId,
            displayName,
            photoURL,
            createdAt: new Date().toISOString(),
          });
        } else {
          const dislikeDocument = resultDocuments.docs[0];
          await dislikeCollection.doc(dislikeDocument.id).delete();
        }
      }
      dispatch(hollerUIStopLoading());
    } catch (err) {
      console.error("err", JSON.stringify(err));
      dispatch(hollerUIStopLoading());
    }
  };
};

export const queryHollerLikes = (hollerId) => {
  return likeCollection.where("hollerId", "==", hollerId);
};

export const queryHollerDislikes = (hollerId) => {
  return dislikeCollection.where("hollerId", "==", hollerId);
};

export const getHollerRatings = (hollerId) => {
  return async (dispatch) => {
    try {
      const likeQuery = likeCollection.where("hollerId", "==", hollerId);
      const likeResults = await likeQuery.get();
      const likeCount = likeResults.size;

      const dislikeQuery = dislikeCollection.where("hollerId", "==", hollerId);
      const dislikeResults = await dislikeQuery.get();
      const dislikeCount = dislikeResults.size;
      const ratings = { likeCount, dislikeCount };

      dispatch(setHollerRatings(ratings));
    } catch (err) {
      console.log("err", JSON.stringify(err));
    }
  };
};

export const queryHollerByUser = (category, userId) => {
  try {
    let query = hollerCollection;
    // const currentDate = new Date().toISOString();
    query = category
      ? query.where("d.categories", "array-contains", category)
      : query;
    query = userId ? query.where("d.userId", "==", userId) : query;
    // query = query.where("d.expireAt", ">=", currentDate);
    return query;
  } catch (err) {
    console.log("err", err);
    return null;
  }
};

export const setLiveMapHoller = (hollerId) => {
  return async (dispatch, getState) => {
    try {
      const hollerDoc = await hollerCollection.doc(hollerId).get();
      const filter = getState().holler.filter;
      if (hollerDoc.exists) {
        const hollerData = hollerDoc.data().d;
        const { location } = hollerData;
        dispatch(
          setHollerFilter({
            ...filter,
            radius: 3,
            location,
          })
        );
        dispatch(
          setLiveMapHollerSelect({
            [hollerId]: { docId: hollerId, ...hollerData },
          })
        );
        const region = {
          latitude: location.latitude,
          longitude: location.longitude,
        };
        dispatch(setSelectedMarker({ docId: hollerId, ...hollerData }));
        dispatch(setRegionChange(region));
      }
    } catch (err) {
      console.log("err", JSON.stringify(err));
    }
  };
};

export const setLatestHollers = (latestHollers) => {
  return {
    type: GET_LATEST_HOLLERS,
    latestHollers: latestHollers,
  };
};

export const setFeaturedHollers = (featuredHollers) => {
  return {
    type: GET_FEATURED_HOLLERS,
    featuredHollers: featuredHollers,
  };
};

export const resetHollers = (hollers) => {
  return {
    type: RESET_HOLLERS,
    hollers: hollers,
  };
};

export const resetLatestHollers = (latestHollers) => {
  return {
    type: RESET_LATEST_HOLLERS,
    latestHollers: latestHollers,
  };
};

export const resetFeaturedHollers = (featuredHollers) => {
  return {
    type: RESET_FEATURED_HOLLERS,
    featuredHollers: featuredHollers,
  };
};

export const setHoller = (holler) => {
  return {
    type: GET_HOLLER,
    holler: holler,
  };
};

export const setEditHoller = (editHoller) => {
  return {
    type: SET_EDIT_HOLLER,
    editHoller: editHoller,
  };
};

export const setConfirmFeatureHoller = (featureHollerCandidate) => {
  return {
    type: SET_CONFIRM_FEATURE_HOLLER,
    featureHollerCandidate: featureHollerCandidate,
  };
};

export const setHollerRatings = (ratings) => {
  return {
    type: HOLLER_RATINGS,
    ratings: ratings,
  };
};

export const setHollerFilter = (filter) => {
  return {
    type: SET_HOLLER_FILTER,
    filter: filter,
  };
};

export const setLatestHollerSnapshot = (hollerLatestSnapshot) => {
  return {
    type: HOLLER_LATEST_SNAPSHOT,
    hollerLatestSnapshot: hollerLatestSnapshot,
  };
};

export const setFeaturedHollerSnapshot = (hollerFeaturedSnapshot) => {
  return {
    type: HOLLER_FEATURED_SNAPSHOT,
    hollerFeaturedSnapshot: hollerFeaturedSnapshot,
  };
};

export const setLiveMapHollerSelect = (liveMapHollerSelect) => {
  return {
    type: HOLLER_LIVE_MAP_SELECT,
    liveMapHollerSelect: liveMapHollerSelect,
  };
};
