import { store } from "../../shared/firebase";
import { GET_NOTIFICATIONS } from "../types/notificationTypes";

const NOTIFICATION_TIMESTAMP = "notificationTimeStamp";
const notificationsCollection = store.collection("notifications");

export const queryNotifications = userId => {
  try {
    return notificationsCollection
      .orderBy("createdAt", "desc")
      .where("receiver", "==", userId);
  } catch (err) {
    // note: stringify to reveal composite index link for complex queries
    console.log("err", JSON.stringify(err));
  }
};

export const setNotifications = notifications => {
  return {
    type: GET_NOTIFICATIONS,
    notifications: notifications
  };
};

export const storeNotificationsTimestamp = notificationTimeStamp => {
  localStorage.setItem(NOTIFICATION_TIMESTAMP, notificationTimeStamp);
};

export const getNotificationsTimestamp = () => {
  return localStorage.getItem(NOTIFICATION_TIMESTAMP);
};

export const removeNotificationTimestamp = () => {
  localStorage.removeItem(NOTIFICATION_TIMESTAMP);
};
