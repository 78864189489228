import {
  GET_MEMBERS,
  RESET_MEMBERS,
  SET_MEMBER,
  SET_MEMBER_META,
  MEMBERS_LATEST_SNAPSHOT,
} from "../types/membersTypes";

const initialState = {
  membersSnapshot: null,
  members: {},
  member: null,
  memberMeta: null,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_MEMBERS:
      return {
        ...state,
        members: {
          ...state.members,
          ...action.members,
        },
      };
    case SET_MEMBER:
      return {
        ...state,
        member: action.member,
      };
    case SET_MEMBER_META:
      return {
        ...state,
        memberMeta: action.memberMeta,
      };
    case RESET_MEMBERS:
      return {
        ...state,
        members: action.members,
      };
    case MEMBERS_LATEST_SNAPSHOT: {
      return {
        ...state,
        membersSnapshot: action.membersSnapshot,
      };
    }
    default:
      return state;
  }
};

export default reducer;
