import React, { useState, useEffect, useCallback } from "react";
// import { useHistory } from "react-router-dom";
import ImageUploader from "react-images-upload";
import isEmpty from "lodash.isempty";
// @material-ui/core components
import { withStyles, makeStyles, useTheme } from "@material-ui/core/styles";
import InputAdornment from "@material-ui/core/InputAdornment";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import FormControl from "@material-ui/core/FormControl";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import loadImage from "blueimp-load-image";
import { useDispatch, useSelector } from "react-redux";

// @material-ui/icons
import Icon from "@material-ui/core/Icon";
import CloseIcon from "@material-ui/icons/Close";
import CircularProgress from "@material-ui/core/CircularProgress";

import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import CustomInput from "components/CustomInput/CustomInput";
import Button from "components/CustomButtons/Button";
import {
  setEditHoller,
  createHoller,
  updateHoller,
} from "../../store/actions/holler";
import {
  hollerUpdateStartLoading,
  setHollerModal,
  setLoginModal,
  setSnackBarMessage,
} from "../../store/actions/ui";
import { checkValidity } from "../../shared/utility";
import { isExceedLimit } from "../../store/utils/plans";
import { displayAddress } from "../../store/utils/places";
import { isValidWebUrl } from "../../store/utils/images";
import { emojis } from "../../variables/general";
import {
  HOLLER_TITLE_ERROR,
  HOLLER_DESC_ERROR,
  CHARACTER_LIMIT,
  IMAGE_UPLOAD_INSTRUCTION,
  PROCESSING_IMAGES,
  IMAGE_ORIENTATION_ADVISORY,
} from "../../constants/messages";
import materialTheme from "../../constants/theme";
import {
  categories,
  categoryForId,
  DEFAULT_CATEGORY_ALT,
} from "../../constants/categories";

import signupPageStyle from "assets/jss/material-kit-pro-react/views/signupPageStyle";
import { ADMIN_TYPE } from "../../constants/entities";
import Link from "@material-ui/core/Link";
import { RULES_ROUTE } from "../../constants/routes";

const { COLORS } = materialTheme;

const { LABEL } = COLORS;

const emojiIndex = Math.floor(Math.random() * emojis.length + 1) - 1;
const MAX_LIMIT = 6;

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
    // display: "flex",
    // alignItems: "center"
  },
}))(MuiDialogContent);

// const DialogActions = withStyles(theme => ({
//   root: {
//     margin: 0,
//     padding: theme.spacing(1)
//   }
// }))(MuiDialogActions);
const useStyles = makeStyles(signupPageStyle);

const edit = "edit";
const create = "create";

const meta = {
  [edit]: {
    title: "Edit Holler",
    subtitle: "Review your edits below",
    buttonLabel: "Save Changes",
  },
  [create]: {
    title: "Create Holler",
    subtitle: "Enter details!",
    buttonLabel: "Holler Now",
  },
};

const rules = {
  title: {
    required: true,
    touched: false,
    maxLength: 60,
    errorMsg: HOLLER_TITLE_ERROR,
    errorMsgB: CHARACTER_LIMIT(60),
  },
  description: {
    required: false,
    touched: false,
    maxLength: 720,
    errorMsg: HOLLER_DESC_ERROR,
    errorMsgB: CHARACTER_LIMIT(720),
  },
};

// const S5FrameOrSmaller = (width, height) => {
//   return height <= 650;
// };

const enableCreateBtn = (title, description, plan) => {
  return (
    checkValidity(title, rules.title) &&
    checkValidity(description, rules.description) &&
    !isExceedLimit(plan)
  );
};

const enableSaveBtn = (title, description) => {
  return (
    checkValidity(title, rules.title) &&
    checkValidity(description, rules.description)
  );
};

const enableSubmit = (accountType, title, description, plan) => {
  return accountType === create
    ? !enableCreateBtn(title, description, plan)
    : !enableSaveBtn(title, description);
};

const handleTextChange = (name, value, setField) => {
  const rule = rules[name];
  rule.touched = true;
  setField(value);
};

const resetRules = () => {
  Object.keys(rules).forEach((ruleName) => {
    rules[ruleName].touched = false;
  });
};

// const resetRules = () => {
//   Object.keys(rules).forEach(ruleName => {
//     rules[ruleName].touched = false;
//   });
// };

const renderTextField = (
  name,
  value,
  placeholder,
  icon,
  classes,
  setField,
  multiline = false,
  rows = 5
) => {
  const rule = rules[name];
  const invalid = rule.touched && !checkValidity(value, rule);
  const errorMsg = !value ? rule.errorMsg : rule.errorMsgB;
  return (
    <CustomInput
      formControlProps={{
        fullWidth: true,
      }}
      inputProps={{
        startAdornment: (
          <InputAdornment position="start" className={classes.inputAdornment}>
            <Icon className={classes.inputAdornmentIcon}>{icon}</Icon>
          </InputAdornment>
        ),
        placeholder: placeholder,
        onChange: (event) =>
          handleTextChange(name, event.target.value, setField),
        type: "text",
        value: value || "",
        multiline,
        rows: rows,
      }}
      success={rule.touched && !invalid}
      error={invalid}
      labelText={invalid && errorMsg}
    />
  );
};

const renderSelect = (value, icon, onChange, classes) => {
  return (
    <FormControl
      fullWidth
      className={classes.selectFormControl}
      style={{
        margin: "0 0 17px 0 !important",
        paddingTop: 27,
      }}
    >
      {/*<InputLabel htmlFor="simple-select" className={classes.selectLabel}>*/}
      {/*  Category*/}
      {/*</InputLabel>*/}
      <Select
        MenuProps={{
          className: classes.selectMenu,
        }}
        classes={{
          select: classes.select,
        }}
        value={value}
        onChange={onChange}
        inputProps={{
          name: "categorySelect",
          id: "category-select",
        }}
      >
        <MenuItem
          disabled
          classes={{
            root: classes.selectMenuItem,
          }}
        >
          Select a category
        </MenuItem>
        {categories.map(({ title, id }) => (
          <MenuItem
            classes={{
              root: classes.selectMenuItem,
              selected: classes.selectMenuItemSelected,
            }}
            value={id}
            key={id}
          >
            {title}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default function HollerEditModal(props) {
  const theme = useTheme();
  const hollerUpdateIsLoading = useSelector(
    (state) => state.ui.hollerUpdateIsLoading
  );
  const hollerDisplay = useSelector((state) => state.ui.hollerDisplay);
  const editHoller = useSelector((state) => state.holler.editHoller);
  const selectedAddress = useSelector((state) => state.places.address);
  const selectedPlace = useSelector((state) => state.places.selectedPlace);
  const selectedLocation = useSelector(
    (state) => state.places.selectedLocation
  );
  const plan = useSelector((state) => state.plan.plan);
  const authUser = useSelector((state) => state.auth.authUser);
  const classes = useStyles();
  const fullScreen = useMediaQuery(theme.breakpoints.down("xs"));
  const dispatch = useDispatch();
  // const [blobs, setBlobs] = useState([]);
  const [pendingAuth, setPendingAuth] = useState(false);
  const [hollerTitle, setHollerTitle] = useState(null);
  const [description, setDescription] = useState("");
  const [hollerCategories, setHollerCategories] = useState([
    DEFAULT_CATEGORY_ALT,
  ]);
  const [location, setLocation] = useState(null);
  const [hollerImages, setHollerImages] = useState([]);
  const [hollerVideos, setHollerVideos] = useState([]);
  const [existing, setExistingImages] = useState([]);
  const [uploadedImages, setUploadImages] = useState([]);
  const [accountType, setAccountType] = useState(create);
  const [imageRotationAdvisory, setImageRotationAdvisory] = useState(false);
  const categoryMeta = categoryForId(hollerCategories[0]);

  const resetState = useCallback(() => {
    dispatch(setEditHoller(null));
    dispatch(setHollerModal(false));
    setUploadImages([]);
    setPendingAuth(false);
    setExistingImages(hollerImages); // restore default
    setHollerTitle(null);
    setDescription("");
    setHollerCategories([DEFAULT_CATEGORY_ALT]);
    resetRules();
  }, [dispatch, hollerImages]);

  const submit = useCallback(
    (blobs) => {
      if (!authUser) {
        dispatch(setLoginModal(true));
        setPendingAuth(true);
        return;
      }

      if (accountType === create) {
        dispatch(
          createHoller(
            hollerTitle,
            description,
            hollerCategories[0],
            location,
            blobs
          )
        );
        resetState();
      } else {
        if (editHoller) {
          const deletedImages = [];
          const { images } = editHoller;
          images.forEach(({ downloadURL, ref }) => {
            if (!existing.includes(downloadURL)) {
              deletedImages.push(ref);
            }
          });
          dispatch(
            updateHoller(
              editHoller,
              hollerTitle,
              description,
              hollerCategories[0],
              location,
              blobs,
              deletedImages
            )
          );
        }
      }
    },
    [
      accountType,
      authUser,
      description,
      dispatch,
      editHoller,
      existing,
      hollerCategories,
      hollerTitle,
      location,
      resetState,
    ]
  );

  const handleSubmit = useCallback(() => {
    const convertedBlobs = [];
    let counter = 0;
    if (!isEmpty(uploadedImages)) {
      dispatch(hollerUpdateStartLoading()); // conversion will take time
      dispatch(
        setSnackBarMessage({
          message: PROCESSING_IMAGES,
          snackColor: materialTheme.COLORS.INFO,
          autoHideDuration: 3000,
        })
      );
      uploadedImages.forEach((uploadedUrl, index) => {
        loadImage(
          uploadedUrl,
          (canvas) => {
            canvas.toBlob(
              (blob) => {
                counter++;
                if (blob instanceof Blob) {
                  convertedBlobs.push(blob);
                }
                if (counter === uploadedImages.length) {
                  submit(convertedBlobs);
                }
              },
              "image/jpeg",
              1
            );
          },
          {
            orientation: true,
            canvas: true,
          }
        );
      });
    } else {
      submit(uploadedImages);
    }
  }, [dispatch, submit, uploadedImages]);

  useEffect(() => {
    if (authUser && pendingAuth) {
      setPendingAuth(false);
      handleSubmit();
    }
  }, [authUser, handleSubmit, pendingAuth]);

  useEffect(() => {
    if (editHoller) {
      const { title, desc, images, videos } = editHoller;
      const { address, location, place } = editHoller;
      setAccountType(edit);
      setLocation({
        address,
        latitude: location.latitude,
        longitude: location.longitude,
        place,
      });
      setHollerTitle(title);
      setDescription(desc);
      setHollerCategories(
        isEmpty(editHoller.categories)
          ? [DEFAULT_CATEGORY_ALT]
          : editHoller.categories
      );
      setHollerVideos(videos);
      const assembleImages = [];
      images.forEach(({ downloadURL }) => {
        assembleImages.push(downloadURL);
      });
      setHollerImages(assembleImages); // default images
      setExistingImages(assembleImages); // existing images - always changes
    } else {
      setLocation({
        address: selectedAddress,
        place: selectedPlace,
        latitude: selectedLocation?.latitude,
        longitude: selectedLocation?.longitude,
      });
      setHollerCategories([DEFAULT_CATEGORY_ALT]);
    }
  }, [editHoller, selectedAddress, selectedPlace, selectedLocation, dispatch]);

  const handleCategory = (event) => {
    setHollerCategories([event.target.value]);
  };

  // Dysfunctional => Modifying the default images will affect pictureDataURLs
  // const configurePreviewImages = (existing, uploaded) => {
  //   blobURLS.forEach(blobURL => {
  //     URL.revokeObjectURL(blobURL);
  //   });
  //   const blobURLSNew = [];
  //   uploaded.forEach(blob => {
  //     blobURLSNew.push(URL.createObjectURL(blob));
  //   });
  //   setPreviewImages([...blobURLSNew.reverse(), ...existing]);
  // };

  const onImageChange = (pictureFiles, pictureDataURLs) => {
    const uploaded = [];
    const existing = [];
    pictureDataURLs.forEach((url) => {
      if (isValidWebUrl(url)) {
        existing.push(url);
      } else {
        uploaded.push(url); // base64 data url format
      }
    });

    if (!isEmpty(uploaded) && !imageRotationAdvisory) {
      dispatch(
        setSnackBarMessage({
          message: IMAGE_ORIENTATION_ADVISORY,
          snackColor: materialTheme.COLORS.INFO,
          autoHideDuration: 10000,
        })
      );
      setImageRotationAdvisory(true);
    }

    if (authUser?.profile?.type === ADMIN_TYPE) {
      setUploadImages(uploaded);
    } else {
      const accepted = uploaded.splice(0, MAX_LIMIT - existing.length);
      setUploadImages(accepted);
    }

    setExistingImages(existing);
  };

  const handleClose = () => {
    resetState();
  };

  const metaInfo = meta[accountType];
  const { title, buttonLabel } = metaInfo;

  return (
    <div>
      <Dialog
        fullScreen={fullScreen}
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={hollerDisplay}
        PaperProps={{
          style: {
            alignItems: "center",
          },
        }}
      >
        <DialogTitle id="customized-dialog-title" onClose={handleClose}>
          {/*{S5FrameOrSmaller(width, height) ? title : ""}*/}
          {""}
        </DialogTitle>
        <DialogContent>
          <GridContainer justify="center">
            <GridItem xs={12} sm={12} md={10}>
              <Card
                className={classes.cardSignup}
                style={{
                  backgroundColor: "transparent",
                  boxShadow: "none",
                  padding: "0px 0px",
                }}
              >
                {/*{S5FrameOrSmaller(width, height) ? null : (*/}
                {/*  <h3 className={classes.cardTitle}>{title}</h3>*/}
                {/*)}*/}
                <h3 className={classes.cardTitle}>{title}</h3>
                <CardBody>
                  <GridContainer justify="center">
                    <GridItem xs={12} sm={12} md={12}>
                      <div className={classes.textCenter}>
                        {/*<img*/}
                        {/*  src={logo}*/}
                        {/*  alt="..."*/}
                        {/*  width="36"*/}
                        {/*  height="36"*/}
                        {/*  style={{*/}
                        {/*    borderRadius: 10*/}
                        {/*  }}*/}
                        {/*/>*/}
                        <h5>Address: {displayAddress(location)}</h5>
                      </div>
                      <form
                        className={classes.form}
                        style={{
                          maxHeight: 450,
                        }}
                      >
                        {renderTextField(
                          "title",
                          hollerTitle,
                          categoryMeta.placeHolder, // `What's going on here? ${emojis[emojiIndex]}`,
                          "chat_bubble_outline",
                          classes,
                          setHollerTitle,
                          true,
                          2
                        )}

                        {renderTextField(
                          "description",
                          description,
                          "Description (optional)",
                          "description",
                          classes,
                          setDescription,
                          true,
                          5
                        )}

                        {renderSelect(
                          isEmpty(hollerCategories)
                            ? DEFAULT_CATEGORY_ALT
                            : hollerCategories[0],
                          "category",
                          handleCategory,
                          classes
                        )}

                        {/* We temporary do not allow image modifications when there is a video for existing holler */}
                        {isEmpty(hollerVideos) && (
                          <ImageUploader
                            withIcon={true}
                            withPreview={true}
                            buttonText="Choose images"
                            buttonStyles={{
                              background: LABEL,
                            }}
                            defaultImages={hollerImages}
                            label={IMAGE_UPLOAD_INSTRUCTION}
                            onChange={onImageChange}
                            imgExtension={[".jpg", ".jpeg", ".png"]}
                            maxFileSize={7242880}
                          />
                        )}

                        {isExceedLimit(plan) && (
                          <div
                            style={{
                              textAlign: "center",
                              marginTop: 8,
                            }}
                          >
                            <span>
                              Daily holler limit exceeded.{" "}
                              <Link
                                href={RULES_ROUTE}
                                target="_blank"
                                rel="noopener noreferrer"
                                // onClick={() => {
                                //   dispatch(setLoginModal(true));
                                // }}
                                className={classes.signUp}
                              >
                                Buy more
                              </Link>
                              {"❗️"}
                            </span>
                          </div>
                        )}

                        <div
                          className={classes.textCenter}
                          style={{
                            margin: "17px 0 17px 0",
                          }}
                        >
                          {hollerUpdateIsLoading ? (
                            <CircularProgress
                              className={classes.loadingIndicator}
                              color="secondary"
                              size={30}
                            />
                          ) : (
                            <Button
                              round
                              color="primary"
                              disabled={enableSubmit(
                                accountType,
                                hollerTitle,
                                description,
                                plan
                              )}
                              onClick={handleSubmit}
                            >
                              {buttonLabel}
                            </Button>
                          )}
                        </div>
                      </form>
                    </GridItem>
                  </GridContainer>
                </CardBody>
              </Card>
            </GridItem>
          </GridContainer>
        </DialogContent>
      </Dialog>
    </div>
  );
}
