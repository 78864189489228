import { GET_COMMENTS } from "../types/commentTypes";

const initialState = {
  comments: {}
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_COMMENTS:
      return {
        ...state,
        comments: action.comments
      };
    default:
      return state;
  }
};

export default reducer;
