import { SEND_CONTACT, PENDING_CONTACT } from "../types/contactTypes";

const initialState = {
  contact: null,
  loading: false
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case SEND_CONTACT:
      return {
        ...state,
        contact: action.contact
      };
    case PENDING_CONTACT:
      return {
        ...state,
        loading: action.loading
      };
    default:
      return state;
  }
};

export default reducer;
